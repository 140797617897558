import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

const Hero = () => {
    const { t } = useTranslation();

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });

    return (
        <>
            {isDesktopOrLaptop ? (
                <div class=' bg-resources-hero  bg-cover h-fit text-white bg-bottom'>
                    <div class=' bg-news-gradient'>
                        <div className='flex justify-start mx-32 pt-[16%] pb-16 text-7xl '>
                            {/* <img src={CopyIcon} alt=" " className='h-[80%] bg-green' /> */}
                            <h1 className='pr-2 mb-0 font-extrabold text-white '>
                                {t("proof-title-1")}
                            </h1>
                            <h1 className='mb-0 ml-2 font text-green '>
                                {t("proof-title-2")}{" "}
                            </h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div class=' bg-resources-hero  bg-cover text-white bg-bottom'>
                    <div class=' bg-news-gradient h-full'>
                        <div className='flex flex-col justify-start ml-8 pt-[26%] md:pt-[20%] pb-[10%] text-4xl md:text-6xl leading-tight'>
                            {/* <img src={CopyIcon} alt=" " className='h-[80%] bg-green' /> */}
                            <h1 className='font-extrabold text-white '>
                                {t("proof-title-1")}{" "}
                            </h1>
                            <h1 className='font text-green '>
                                {t("proof-title-2")}{" "}
                            </h1>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Hero;
