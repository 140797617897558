import axios from "axios";

export const GET_EVENTS_LIST = "GET_EVENTS_LIST";
export const GET_SOCIALS_LIST = "GET_SOCIALS_LIST";
export const GET_GALLERY_LIST = "GET_GALLERY_LIST";
// export const ADD_ABOUT = "ADD_ABOUT";
// export const DELETE_ABOUT = "DELETE_ABOUT";
// export const UPDATE_ABOUT = "UPDATE_ABOUT";

const headers = {
  "Accept": "application/json",
  "Content-Type": "application/json",
  "X-Requested-With": "nid-website",
};

export const getEventsList = () => async (dispatch) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_BASE_URL + `/News/EventsNews`,
      {
        headers: headers,
        timeout: 10000,
      }
    );

    if (response && response.data) {
      dispatch({
        type: GET_EVENTS_LIST,
        payload: response.data,
      });
      sessionStorage.setItem("eventsList", JSON.stringify(response.data));
    } else {
      const cachedEventsList = sessionStorage.getItem("eventsList");
      if (cachedEventsList) {
        dispatch({
          type: GET_EVENTS_LIST,
          payload: JSON.parse(cachedEventsList),
        });
      }
    }
  } catch (err) {
    // If the request times out or fails, fetch from sessionStorage
    const cachedEventsList = sessionStorage.getItem("eventsList");
    if (cachedEventsList) {
      dispatch({
        type: GET_EVENTS_LIST,
        payload: JSON.parse(cachedEventsList),
      });
    }
  }
};

export const getGalleryList = () => async (dispatch) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_BASE_URL + `/galleryy/`,
      {
        headers: headers,
        timeout: 10000,
      }
    );

    if (response && response.data) {
      dispatch({
        type: GET_GALLERY_LIST,
        payload: response.data,
      });
      sessionStorage.setItem("galleryList", JSON.stringify(response.data));
    } else {
      const cachedGalleryList = sessionStorage.getItem("galleryList");
      if (cachedGalleryList) {
        dispatch({
          type: GET_GALLERY_LIST,
          payload: JSON.parse(cachedGalleryList),
        });
      }
    }
  } catch (err) {
    // If the request times out or fails, fetch from sessionStorage
    const cachedGalleryList = sessionStorage.getItem("galleryList");
    if (cachedGalleryList) {
      dispatch({
        type: GET_GALLERY_LIST,
        payload: JSON.parse(cachedGalleryList),
      });
    }
  }
};