import axios from "axios";

export const GET_ABOUT_LIST = "GET_ABOUT_LIST";
export const GET_HISTORY_LIST = "GET_HISTORY_LIST";
export const GET_PARTNERS_LIST = "GET_PARTNERS_LIST";
export const GET_HERO_HOME = 'GET_HERO_HOME' 
// export const ADD_ABOUT = "ADD_ABOUT";
// export const DELETE_ABOUT = "DELETE_ABOUT";
// export const UPDATE_ABOUT = "UPDATE_ABOUT";

// export const getAboutList = () => async (dispatch) => {
//   try {
//     const response = await axios.get(
//       process.env.REACT_APP_BASE_URL +
//         `/api/resource/About?fields=["title", "description"]`
//     );

//     if (response && response.data) {
//       dispatch({
//         type: GET_ABOUT_LIST,
//         payload: response.data,
//       });
//       localStorage.setItem("aboutList", JSON.stringify(response.data));
//     } else {
//       const cachedAboutList = localStorage.getItem("aboutList");
//       if (cachedAboutList) {
//         dispatch({
//           type: GET_ABOUT_LIST,
//           payload: JSON.parse(cachedAboutList),
//         });
//       }
//     }
//   } catch (err) {
//     const cachedAboutList = localStorage.getItem("aboutList");
//     if (cachedAboutList) {
//       dispatch({
//         type: GET_ABOUT_LIST,
//         payload: JSON.parse(cachedAboutList),
//       });
//     }
//   }
// };

const headers = {
  "Accept": "application/json",
  "Content-Type": "application/json",
  "X-Requested-With": "nid-website",
};

export const getHistoryList = () => async (dispatch) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_BASE_URL + `/AboutUs/History`,
      {
        headers: headers,
        timeout: 5000, // 5 seconds timeout
      }
    );
  
    if (response && response.data) {
      dispatch({
        type: GET_HISTORY_LIST,
        payload: response.data,
      });
      sessionStorage.setItem("historyList", JSON.stringify(response.data));
    } else {
      const cachedList = sessionStorage.getItem("historyList");
      if (cachedList) {
        dispatch({
          type: GET_HISTORY_LIST,
          payload: JSON.parse(cachedList),
        });
      }
    }
  } catch (err) {
    const cachedList = sessionStorage.getItem("historyList");
    if (cachedList) {
      dispatch({
        type: GET_HISTORY_LIST,
        payload: JSON.parse(cachedList),
      });
    }
  }
};

export const getPartnersList = () => async (dispatch) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_BASE_URL + `/AboutUs/Partners`,
      {
        headers: headers,
        timeout: 5000, // 5 seconds timeout
      }
    );
  
    if (response && response.data) {
      dispatch({
        type: GET_PARTNERS_LIST,
        payload: response.data,
      });
      sessionStorage.setItem("partnerList", JSON.stringify(response.data));
    } else {
      const cachedList = sessionStorage.getItem("partnerList");
      if (cachedList) {
        dispatch({
          type: GET_PARTNERS_LIST,
          payload: JSON.parse(cachedList),
        });
      }
    }
  } catch (err) {
    const cachedList = sessionStorage.getItem("partnerList");
    if (cachedList) {
      dispatch({
        type: GET_PARTNERS_LIST,
        payload: JSON.parse(cachedList),
      });
    }
  }
};