import axios from "axios";


export const GET_LOCATIONS = "GET_LOCATIONS";


const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "X-Requested-With": "nid-website",
};

export const getLocations = () => async (dispatch) => {
  
  try {
    const response = await axios.get(
      process.env.REACT_APP_BASE_URL +
        `/Locations/`,
      {
        headers: headers,
        timeout: 10000,
      }
    );
  
    if (response && response.data) {
      dispatch({
        type: GET_LOCATIONS,
        payload: response.data,
      });
      sessionStorage.setItem("locationsList", JSON.stringify(response.data));
    } else {
      const cachedLocationsList = sessionStorage.getItem("locationsList");
      if (cachedLocationsList) {
        dispatch({
          type: GET_LOCATIONS,
          payload: JSON.parse(cachedLocationsList),
        });
      }
    }
  } catch (err) {
    const cachedLocationsList = sessionStorage.getItem("locationsList");
    if (cachedLocationsList) {
      dispatch({
        type: GET_LOCATIONS,
        payload: JSON.parse(cachedLocationsList),
      });
    }
  }
  
};