import axios from "axios";

export const GET_BLOG_LIST = "GET_BLOG_LIST";

export const getBlogList = () => async (dispatch) => {

  try {
    const response = await axios.get(
      process.env.REACT_APP_BASE_URL + `/Blogs/`,
      { timeout: 5000 }
    );

    if (response && response.data) {
      dispatch({
        type: GET_BLOG_LIST,
        payload: response.data,
      });
      sessionStorage.setItem("blogList", JSON.stringify(response.data));
    } else {
      const cachedBlogList = sessionStorage.getItem("blogList");
      if (cachedBlogList) {
        dispatch({
          type: GET_BLOG_LIST,
          payload: JSON.parse(cachedBlogList),
        });
      }
    }
  } catch (err) {
    const cachedBlogList = sessionStorage.getItem("blogList");
    if (cachedBlogList) {
      dispatch({
        type: GET_BLOG_LIST,
        payload: JSON.parse(cachedBlogList),
      });
    }
  }
};