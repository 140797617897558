import React, { useContext, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import FooterService from "../../components/footer/footer";
import TopBar from "../../components/navbar/navbar";
import Sidebar from "../../components/sidebar/sidebar";
import { ViewContext } from "../../providers/viewContext";
import i18next from "i18next";

const FooterPolicies = () => {
    const { view, setView } = useContext(ViewContext);
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            {isDesktopOrLaptop ? (
                <TopBar viewState={view ? "citizen" : "stakeholder"} />
            ) : (
                <Sidebar />
            )}
            {i18next.language == "en" ? (
                <>
                    <div class='bg-faq bg-center bg-cover flex '>
                        <div className='bg-policy-hero-gradient flex h-full w-full '>
                            <div className='flex justify-start text-4xl md:text-8xl  w-full md:pt-56 pt-32 md:pl-40 pl-10'>
                                <h1 className='pr-2 pb-10 font-bold  text-white w-full '>
                                    Website Policies and Notices
                                </h1>
                            </div>
                        </div>
                    </div>

                    <div class='container mx-auto px-4 py-8 '>
                        <p class='mb-4'>
                            Welcome to Fayda Id, the official website of the
                            National ID Program (NIDP). We are committed to
                            protecting your privacy and providing you with the
                            most accurate and up-to-date information about our
                            services and policies. In order to ensure that we
                            maintain the highest standards of transparency and
                            accountability, we have developed the following
                            policies that govern the use of this website.
                        </p>

                        <h2 class='text-xl font-bold mb-2'>
                            1. Information Collected
                        </h2>
                        <p class='mb-4'>
                            We collect information from visitors to our website
                            in order to improve the quality of our services and
                            to better understand our audience. This information
                            includes your IP address, the pages you visit on our
                            site, and any information you provide when you fill
                            out a form or request information. We do not share
                            this information with third parties except as
                            required by law or as necessary to provide you with
                            the services you request.
                        </p>

                        <h2 class='text-xl font-bold mb-2'>2. Cookies</h2>
                        <p class='mb-4'>
                            We use cookies to improve the functionality of our
                            website and to personalize your experience. These
                            cookies do not collect any personal information and
                            can be disabled at any time by changing your browser
                            settings.
                        </p>

                        <h2 class='text-xl font-bold mb-2'>
                            3. Links to Third-Party Sites
                        </h2>
                        <p class='mb-4'>
                            Our website may contain links to third-party
                            websites that are not under our control. We are not
                            responsible for the privacy practices or content of
                            these websites and encourage you to review their
                            policies before providing any personal information.
                        </p>

                        <h2 class='text-xl font-bold mb-2'>
                            4. Information Security
                        </h2>
                        <p class='mb-4'>
                            We take reasonable measures to protect the
                            information we collect from unauthorized access,
                            disclosure, or alteration. However, no data
                            transmission over the internet can be guaranteed to
                            be 100% secure, and we cannot ensure or warrant the
                            security of any information you provide to us.
                        </p>

                        <h2 class='text-xl font-bold mb-2'>
                            5. Copyright and Trademarks
                        </h2>
                        <p class='mb-4'>
                            All content on this website, including text,
                            graphics, logos, images, and software, is the
                            property of the National ID Program or its licensors
                            and is protected by copyright and trademark laws.
                            You may not use or reproduce any content without our
                            express written permission.
                        </p>

                        <h2 class='text-xl font-bold mb-2'>
                            6. Changes to this Policy
                        </h2>
                        <p class='mb-4'>
                            We reserve the right to modify or update this policy
                            at any time without prior notice. Any changes will
                            be posted on this page, and your continued use of
                            the website constitutes your acceptance of the
                            revised policy.
                        </p>

                        <p class='mb-4'>
                            By using Fayda, you agree to abide by these policies
                            and to use our website only for lawful purposes. If
                            you have any questions or concerns about our
                            policies, please contact us at{" "}
                            <a
                                href='mailto:info@id.gov.et'
                                class='font-bold text-blue-600'
                            >
                                info@id.gov.et
                            </a>
                            .
                        </p>
                    </div>
                </>
            ) : (
                <>
                    <div class='bg-faq bg-center bg-cover flex '>
                        <div className='bg-hero-gradient flex h-full w-full '>
                            <div className='flex justify-start text-4xl md:text-8xl  w-full md:pt-56 pt-32 md:pl-40 pl-10'>
                                <h1 className='pr-2 pb-10 font-bold  text-white w-full '>
                                    የድህረ-ገፅ ፖሊሲ እና ማስታወቂያዎች
                                </h1>
                            </div>
                        </div>
                    </div>

                    <div class='container mx-auto px-4 py-8 '>
                        <p class='mb-4'>
                            እንኳን ወደ ፋይዳ መታወቂያ፣ የብሔራዊ መታወቂያ ፕሮግራም ድህረ ገፅ በደህና መጡ።
                            የእርስዎን ግላዊ መረጃ ለመጠበቅ እና ስለአገልግሎቶቻችን እና ፖሊሲዎቻችን ትክክለኛ
                            እና ወቅታዊ መረጃ ለእርስዎ ለማቅረብ ቁርጠኞች ነን። የግልጽነት እና የተጠያቂነት
                            ለማረጋገጥ፣ የዚህን ድህረ-ገጽ አጠቃቀም የሚገዙ የሚከተሉትን ፖሊሲዎች
                            አዘጋጅተናል።
                        </p>

                        <h2 class='text-xl font-bold mb-2'>1. የተሰበሰበ መረጃ፡- </h2>
                        <p class='mb-4'>
                            የአገልግሎታችንን ጥራት ለማሻሻል እና ተገልጋዮቻችንን በደንብ ለመረዳት
                            ከድህረ-ገጻችን ጎብኝዎች መረጃ እንሰበስባለን። ይህ መረጃ የእርስዎን አይፒ
                            አድራሻ፣ በጣቢያችን ላይ የሚጎበኟቸውን ገፆች እና ፎርም ሲሞሉ ወይም መረጃ ሲጠይቁ
                            የሚያቀርቡትን ማንኛውንም መረጃ ያካትታል። ይህንን መረጃ በህግ ከተደነገገው
                            በስተቀር ወይም እርስዎ የጠየቁትን አገልግሎት ለእርስዎ ለመስጠት እንደ አስፈላጊነቱ
                            ካልሆነ በስተቀር ለሶስተኛ ወገኖች አናጋራም።
                        </p>

                        <h2 class='text-xl font-bold mb-2'>2. ኩኪዎች፦ </h2>
                        <p class='mb-4'>
                            የድረ-ገጻችንን ተግባራዊነት ለማሻሻል እና የእርስዎን ተሞክሮ ለግልዎ በሚመች መልኩ
                            ለማዘጋጀት ኩኪዎችን እንጠቀማለን። እነዚህ ኩኪዎች ምንም አይነት የግል መረጃ
                            አይሰበስቡም፣ እንዲሁም በማንኛውም ግዜ የአሳሾን ቅንብሮች (browser
                            settings) በመቀየር ኩኪዎችን ማስቆም ይችላሉ።
                        </p>

                        <h2 class='text-xl font-bold mb-2'>
                            3. የሶስተኛ ወገን ድህረ-ገጾች አገናኞች (links)፦
                        </h2>
                        <p class='mb-4'>
                            የእኛ ድረ-ገጽ በእኛ ቁጥጥር ስር ላልሆኑ የሶስተኛ ወገን ድህረ-ገጾች አገናኞችን
                            (links) ሊይዝ ይችላል። ለእነዚህ ድህረ-ገጾች የግላዊነት ልምዶች (privacy
                            practices) ወይም ይዘቶች ተጠያቂ አይደለንም። ስለሆነም ማንኛውንም የግል
                            መረጃ ከማጋራቶ በፊት ፖሊሲዎቻቸውን እንዲገመግሙ እናበረታታሃለን።
                        </p>

                        <h2 class='text-xl font-bold mb-2'>4. የመረጃ ደህንነት፦</h2>
                        <p class='mb-4'>
                            የምንሰበስበውን መረጃ ካልተፈቀደለት ተደራሽነት፣ ይፋ ከማድረግ ወይም ከመቀየር
                            ለመጠበቅ ምክንያታዊ እርምጃዎችን እንወስዳለን። ነገር ግን ምንም አይነት የመረጃ
                            ስርጭት በበይነመረቡ ላይ 100% ደህንነቱ የተጠበቀ ስለመሆኑ ዋስትና ልንሰጥ
                            አንችልም። እርስዎ ለእኛ የሚሰጡትን ማንኛውንም መረጃ ደህንነት ማረጋገጥ አንችልም።
                        </p>

                        <h2 class='text-xl font-bold mb-2'>
                            5. የቅጂ መብት እና የንግድ ምልክቶች፦
                        </h2>
                        <p class='mb-4'>
                            በዚህ ድህረ ገጽ ላይ ያሉ ሁሉም ይዘቶች ጽሑፍ፣ ግራፊክስ፣ አርማዎች፣ ምስሎች እና
                            ሶፍትዌሮች የብሔራዊ መታወቂያ ፕሮግራም ወይም የፍቃድ ሰጪዎቹ ንብረት በመሆናቸው
                            በአእምሯዊ መብት ህጎች የተጠበቁ ናቸው። ያለየብሔራዊ መታወቂያ ፕሮግራም ግልጽ
                            የጽሁፍ ፍቃድ ማንኛውንም ይዘት መጠቀም ወይም ማባዛት አይቻልም።
                        </p>

                        <h2 class='text-xl font-bold mb-2'>
                            6. በዚህ ፖሊሲ ላይ የተደረጉ ለውጦች፦
                        </h2>
                        <p class='mb-4'>
                            ያለቅድመ ማስታወቂያ ይህንን ፖሊሲ በማንኛውም ጊዜ የማሻሻል ወይም የማዘመን
                            መብታችን የተጠበቀ ነው። ማንኛውም ለውጦች በዚህ ገጽ ላይ ይለጠፋሉ። የድህረ ገጹን
                            መጠቀም ከቀጠሉ የተሻሻለውን ፖሊሲ እንደተቀበሉ ይቆጠራል።
                        </p>

                        <h2 class='text-xl font-bold mb-2'>አድራሻ</h2>
                        <p class='mb-4'>
                            ፋይዳ በመጠቀም እነዚህን መመሪያዎች ለማክበር እና የእኛን ድረ-ገጽ ለሕጋዊ
                            ዓላማዎች ብቻ ለመጠቀም ተስማተዋል። ስለ ፖሊሲያችን ማንኛውም አይነት ጥያቄ ወይም
                            ስጋት ካለዎት እባክዎን በinfo@id.gov.et ያፃፉልን።
                        </p>
                    </div>
                </>
            )}

            <FooterService />
        </>
    );
};

export default FooterPolicies;
