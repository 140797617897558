import React, { useContext, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import FooterService from "../../components/footer/footer";
import TopBar from "../../components/navbar/navbar";
import Sidebar from "../../components/sidebar/sidebar";
import { ViewContext } from "../../providers/viewContext";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const AppPrivacy = () => {
    const { view, setView } = useContext(ViewContext);
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const { t } = useTranslation();
    const openEmailApp = () => {
        const email = "info@id.et";
        const subject = "";
        const body = "";
        const emailUrl = `mailto:${email}?subject=${encodeURIComponent(
            subject
        )}&body=${encodeURIComponent(body)}`;
        window.location.href = emailUrl;
    };

    const openMapApp = () => {
        const mapUrl =
            "https://www.google.com/maps?q=9.021224827844636,38.764456897526905";
        window.location.href = mapUrl;
    };

    const openDialer = (phoneNumber) => {
        const phoneUrl = `tel:${phoneNumber}`;
        window.location.href = phoneUrl;
    };

    return (
        <>
            {isDesktopOrLaptop ? (
                <TopBar viewState={view ? "citizen" : "stakeholder"} />
            ) : (
                <Sidebar />
            )}
            {i18next.language == "en" ? (
                <>
                    <div class='bg-faq bg-center bg-cover flex'>
                        <div className='bg-term-hero-gradient flex h-full w-full'>
                            <div className='flex justify-start text-4xl md:text-8xl w-full md:pt-56 pt-32 md:pl-40 pl-10'>
                                <h1 className='pr-2 pb-10 font-bold text-white w-full'>
                                    Fayda App Privacy Policy
                                </h1>
                            </div>
                        </div>
                    </div>

                    <div class='container mx-auto px-4 py-8'>
                        <div>
                            <p className='text-lg font-bold'>
                                {t("privacy-welcome")}
                            </p>
                            <p>{t("privacy-welcome-description")}</p>
                            <p>{t("privacy-welcome-description-1")}</p>
                        </div>

                        <div>
                            <p className='text-lg font-bold'>
                                {t("privacy-collect-title")}
                            </p>
                            <p>{t("privacy-collect-sub-title")}</p>
                            <div className='indent-5'>
                                <p>
                                    <span className='font-bold'>
                                        1. {t("privacy-collect-1")}
                                    </span>{" "}
                                    <p>{t("privacy-collect-1-description")}</p>
                                </p>
                                <p>
                                    <span className='font-bold'>
                                        2. {t("privacy-collect-2")}
                                    </span>{" "}
                                    <p>{t("privacy-collect-2-description")}</p>
                                </p>
                                <p>
                                    <span>{"\u2022"}</span>{" "}
                                    {t("privacy-collect-bullet")}
                                </p>
                            </div>
                        </div>

                        <div>
                            <p className='text-lg font-bold'>
                                {t("privacy-collect-reason-title")}
                            </p>
                            <p>{t("privacy-collect-reason-subtitle")}</p>

                            <div className='indent-5'>
                                <p>
                                    <span className='font-bold'>
                                        <span>{"\u2022"}</span>{" "}
                                        {t("privacy-collect-reason-1")}
                                    </span>{" "}
                                    <p>
                                        {t(
                                            "privacy-collect-reason-1-description"
                                        )}
                                    </p>
                                </p>

                                <p>
                                    <span className='font-bold'>
                                        <span>{"\u2022"}</span>{" "}
                                        {t("privacy-collect-reason-2")}
                                    </span>{" "}
                                    <p>
                                        {t(
                                            "privacy-collect-reason-2-description"
                                        )}
                                    </p>
                                </p>

                                <p>
                                    <span className='font-bold'>
                                        <span>{"\u2022"}</span>{" "}
                                        {t("privacy-collect-reason-3")}
                                    </span>{" "}
                                    <p>
                                        {t(
                                            "privacy-collect-reason-3-description"
                                        )}
                                    </p>
                                </p>
                            </div>
                        </div>

                        <div>
                            <p className='text-lg font-bold'>
                                {t("privacy-rights-title")}
                            </p>
                            <p>{t("privacy-rights-description")}</p>

                            <div className='indent-5'>
                                <p>
                                    <span>
                                        <span>{"\u2022"}</span>{" "}
                                        {t("privacy-rights-bulletin-1")}
                                    </span>
                                </p>

                                <p>
                                    <span>
                                        <span>{"\u2022"}</span>{" "}
                                        {t("privacy-rights-bulletin-2")}
                                    </span>
                                </p>

                                <p>
                                    <span>
                                        <span>{"\u2022"}</span>{" "}
                                        {t("privacy-rights-bulletin-3")}
                                    </span>
                                </p>
                                <p>
                                    <span>
                                        <span>{"\u2022"}</span>{" "}
                                        {t("privacy-rights-bulletin-4")}
                                    </span>
                                </p>
                            </div>
                        </div>

                        <div>
                            <p className='text-lg font-bold'>
                                {t("we-never-share")}
                            </p>
                            <p>{t("we-never-share-description")}</p>
                        </div>

                        <div>
                            <p className='text-lg font-bold'>
                                {t("protect-info-title")}
                            </p>
                            <p>{t("protect-info-description")}</p>

                            <div className='indent-5'>
                                <p>
                                    <span>
                                        <span>{"\u2022"}</span>{" "}
                                        {t("protect-info-bulletin-1")}
                                    </span>
                                </p>
                                <p>
                                    <span>
                                        <span>{"\u2022"}</span>{" "}
                                        {t("protect-info-bulletin-2")}
                                    </span>
                                </p>
                            </div>
                        </div>

                        <div>
                            <p className='text-lg font-bold'>
                                {t("keeping-you-informed-title")}
                            </p>
                            <p>{t("keeping-you-informed-description")}</p>
                        </div>

                        <div>
                            <p className='text-lg font-bold'>
                                {t("contact-us-privacy")}
                            </p>
                            <p>{t("contact-us-privacy-description")}</p>

                            <div className='indent-5'>
                                <button onClick={openEmailApp}>
                                    <p>
                                        <span>{"\u2022"}</span> Email Address:
                                        info@id.et
                                    </p>
                                </button>

                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 4,
                                    }}
                                >
                                    <p>
                                        <span>{"\u2022"}</span> Phone:
                                    </p>
                                    <button
                                        onClick={() =>
                                            openDialer("+251113720006")
                                        }
                                    >
                                        <p>+251 113 720 006 /</p>
                                    </button>
                                    <button onClick={() => openDialer("9779")}>
                                        <p>9779</p>
                                    </button>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 4,
                                    }}
                                >
                                    <p>
                                        <span>{"\u2022"}</span>Address:
                                    </p>
                                    <button onClick={openMapApp}>
                                        Grand Palace Parking, Menelik II Avenue
                                        2nd floor, office number-23 Addis Ababa
                                        Ethiopia
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <> </>
            )}

            <FooterService />
        </>
    );
};

export default AppPrivacy;
