import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import Help from "../../../../assets/help.png";

const GetHelp = ({ id }) => {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <>
            {isDesktopOrLaptop ? (
                <div
                    id={id}
                    className='relative flex flex-col px-32 mt-36 mb-7 '
                >
                    <div id='help' className='relative flex flex-row pb-5 '>
                        <div className='flex flex-col text-3xl w-[50%] '>
                            <h1 className='mb-3 font-semibold  text-MetallicBlue md:text-6xl'>
                                {t("services-get-help")}
                            </h1>
                            {/*    <h3 className='pb-4 font-light  text-green md:text-5xl'>
                                {t("services-get-fayda-text1")}
                            </h3> */}

                            <p className=' text-md md:text-2xl opacity-60 text-justify leading-tight mt-5 mb-5'>
                                {t("services-get-help-text2")}
                            </p>
                        </div>

                        <div className='relative flex w-[46%] h-100 -right-8 rounded-2xl mb-26'>
                            <img
                                src={Help}
                                alt='tester'
                                className='object-contain'
                            />
                        </div>
                    </div>

                    <div className='relative bottom-8 '>
                        <div className='flex items-center justify-between my-4 mb-20 text-xl font-semibold  '>
                            <button
                                className='flex justify-center items-center px-20 py-4 h-14 w-fit text-[#083247] shadow-md bg-transparent border border-[#083247] rounded-full hover:bg-[#083247] hover:text-white transition-all transform duration-300 '
                                onClick={() => navigate("/help")}
                            >
                                {t("services-help-button")}
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                //******************** */ Mobie VERSION ******************
                <>
                    <div id={id} className='flex flex-col px-8 mt-12 mb-7'>
                        <div className='flex items-start w-full mb-5 '>
                            <div className='flex flex-col items-center justify-center text-4xl'>
                                <h1 className='mb-1 text-4xl font-extrabold  text-MetallicBlue md:text-6xl'>
                                    {t("services-get-help")}
                                </h1>
                                <hr className='w-[20%] border-b-2 mb-5 ml-0.5 border-green' />

                                {/*                                 <h3 className='pb-4 text-xl font-medium text-left  text-darkblue md:text-5xl'>
                                    {t("services-get-fayda-text1")}
                                </h3> */}

                                <p className='text-lg text-justify  md:text-2xl text-darkblue'>
                                    {t("services-get-help-text2")}
                                </p>
                            </div>
                        </div>

                        <div className='relative flex w-full mb-6 h-52 rounded-2xl'>
                            {/* <div className="absolute w-full h-full border-2 -right-2 brightness-95 top-3 rounded-2xl border-green"></div> */}
                            <img
                                src={Help}
                                alt='tester'
                                className='relative object-cover w-full h-full overflow-hidden brightness-110 rounded-xl'
                            />
                        </div>

                        <div className='flex flex-col px-1'>
                            <div className='flex flex-col items-center w-full gap-2 my-4 mb-5 text-base font-semibold  '>
                                <button
                                    className='flex justify-center items-center py-4 h-10 w-fit px-24 text-white/90 shadow-md bg-[#083247] rounded-full hover:bg-green transition-all transform duration-300 '
                                    onClick={() => navigate("/help")}
                                >
                                    {t("services-help-button")}
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {/* <div className="relative w-full h-24 backdrop-brightness-95 "></div> */}
        </>
    );
};

export default GetHelp;
