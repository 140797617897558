import React, { useContext, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import FooterService from "../../components/footer/footer";
import TopBar from "../../components/navbar/navbar";
import Sidebar from "../../components/sidebar/sidebar";
import { ViewContext } from "../../providers/viewContext";
import i18next from "i18next";

const AppTerms = () => {
    const { view, setView } = useContext(ViewContext);
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            {isDesktopOrLaptop ? (
                <TopBar viewState={view ? "citizen" : "stakeholder"} />
            ) : (
                <Sidebar />
            )}
            {i18next.language == "en" ? (
                <>
                    <div class='bg-faq bg-center bg-cover flex'>
                        <div className='bg-term-hero-gradient flex h-full w-full'>
                            <div className='flex justify-start text-4xl md:text-8xl w-full md:pt-56 pt-32 md:pl-40 pl-10'>
                                <h1 className='pr-2 pb-10 font-bold text-white w-full'>
                                    Fayda App Terms and Conditions
                                </h1>
                            </div>
                        </div>
                    </div>

                    <div class='container mx-auto px-4 py-8'>
                        <h2 class='text-xl font-bold mb-2'>
                            1. Terms of use of Fayda App
                        </h2>
                        <p class='mb-4'>
                            By using Fayda App receiving any services including
                            but not limited to access to digital credential, ID
                            verification scanner, Fayda Identification Number
                            resend and status notification services (“the
                            Service”) supplied to you by the National ID Program
                            ("NIDP"), and downloading, installing or using any
                            associated software supplied by NIDP you hereby
                            expressly acknowledge and agree with NIDP to be
                            bound by the terms and conditions of the following
                            terms and conditions (“Terms”) of use and any future
                            amendments and additions to these Terms as published
                            from time to time.
                        </p>
                        <p class='mb-4'>
                            NIDP reserves the right to modify the Terms at any
                            time, effective upon posting of an updated version
                            of the Terms. You are responsible for staying up to
                            date with the Terms. Continued use of the Fayda App
                            after any changes to the terms shall constitute your
                            consent to such changes.
                        </p>

                        <h2 class='text-xl font-bold mb-2'>
                            2. Access restriction
                        </h2>
                        <p class='mb-4'>
                            You are required not to give access to your Fayda
                            App to any other person.
                        </p>

                        <h2 class='text-xl font-bold mb-2'>
                            3. Representations and Warranties
                        </h2>
                        <p class='mb-4'>
                            By using the Fayda App you expressly consent to the
                            actions you made on the App and represent and
                            warrant that you are legally entitled to access the
                            data and the services on the Fayda App and to agree
                            to these Terms. Your use of the Fayda App is for
                            your sole and personal use and to have access to
                            services. You may not authorize others to use your
                            user Fayda ID, and you may not assign or otherwise
                            transfer your ID to any other person or entity
                            except as needed to have access to services.
                        </p>
                        <p class='mb-4'>
                            By using the Fayda App, you agree that you will only
                            use the Fayda App for lawful purposes; you will not
                            use the Fayda App for sending or storing any
                            unlawful material or for fraudulent purposes.
                        </p>

                        <h2 class='text-xl font-bold mb-2'>
                            4. License Grant and Restrictions
                        </h2>
                        <p class='mb-4'>
                            NIDP hereby grants you a non-exclusive,
                            non-transferable, right to use the Fayda App, solely
                            for your own personal purposes. All rights not
                            expressly granted to you are reserved by NIDP.
                        </p>
                        <p class='mb-4'>
                            You shall not (i) license, sublicense, sell, resell,
                            transfer, assign, distribute or otherwise
                            commercially exploit or make available to any third
                            party the Fayda App in any way; (ii) modify or make
                            derivative works based upon the Fayda App; (iii)
                            create Internet "links" to the Fayda App or "frame"
                            or "mirror" any Software on any other server or
                            wireless or Internet-based device; (iv) reverse
                            engineer the Fayda App; (v) attempt to access the
                            Fayda App in order to (a) build a competitive
                            product or service, (b) build a product using
                            similar ideas, features, functions or graphics of
                            the Fayda App Platform, or (c) copy any ideas,
                            features, functions or graphics of the Fayda App
                            Platform, or (vi) launch an automated program or
                            script, including, but not limited to, web spiders,
                            web crawlers, web robots, web ants, web indexers,
                            bots, viruses or worms, or any program which may
                            make multiple server requests per second, or unduly
                            burdens or hinders the operation and/or performance
                            of the Fayda App.
                        </p>
                        <p class='mb-4'>
                            Intellectual Property Ownership: NIDP owns all
                            rights, title and interest, including all related
                            intellectual property rights, in and to the Fayda
                            App Platform. To the extent you provide any
                            suggestions, ideas, enhancement requests, feedback,
                            recommendations or other information, you hereby
                            assign to NIDP all right, title and interest
                            thereto. These Terms are not a sale and do not
                            convey to you any rights of ownership in or related
                            to the Fayda App Platform, or any intellectual
                            property rights owned by NIDP. NIDP name, NIDP logo,
                            and the product names associated with the Fayda App
                            are trademarks of NIDP or third parties, and no
                            right or license is granted to use them.
                        </p>

                        <h2 class='text-xl font-bold mb-2'>
                            5. Indemnification
                        </h2>
                        <p class='mb-4'>
                            By accepting these Fayda App Terms and using the
                            Fayda App Platform, you agree to defend, indemnify
                            and hold NIDP, harmless from and against any and all
                            claims, costs, damages, losses, liabilities and
                            expenses arising out of or in connection with: (a)
                            your violation or breach of these Fayda App terms or
                            any applicable law or regulation, whether or not
                            referenced herein; (b) your violation of any rights
                            of any third party, including providers of services
                            arranged via the Fayda App Platform, or (c) your use
                            or misuse of the Fayda App or the services thereof.
                        </p>

                        <h2 class='text-xl font-bold mb-2'>
                            6. Network Delays
                        </h2>
                        <p class='mb-4'>
                            NIDP's Platform may be subject to limitations,
                            delays, and other problems inherent in the use of
                            the internet, telecommunications networks and
                            electronic communications. NIDP is not responsible
                            for any delays, delivery failures, or other damage
                            resulting from such problems.
                        </p>

                        <h2 class='text-xl font-bold mb-2'>7. Notice</h2>
                        <p class='mb-4'>
                            NIDP may give notice to you by means of a general
                            notice on the Service, electronic mail to your email
                            address on record in NIDP's account information, or
                            by SMS to the number collected upon your use of the
                            service.
                        </p>

                        <h2 class='text-xl font-bold mb-2'>
                            8. Controlling Law and Jurisdiction
                        </h2>
                        <p class='mb-4'>
                            These Fayda App Terms will be interpreted in
                            accordance with the laws of Ethiopia.
                        </p>

                        <h2 class='text-xl font-bold mb-2'>9. General</h2>
                        <p class='mb-4'>
                            If any provision of the Agreement is held to be
                            invalid or unenforceable, such provision shall be
                            struck and the remaining provisions shall be
                            enforced to the fullest extent under law. The
                            failure of NIDP to enforce any right or provision in
                            this Agreement shall not constitute a waiver of such
                            right or provision unless acknowledged and agreed to
                            by NIDP in writing.
                        </p>

                        <h2 class='text-xl font-bold mb-2'>
                            10. Breaches of these terms and conditions
                        </h2>
                        <p class='mb-4'>
                            Without prejudice to NIDP's other rights under these
                            terms and conditions, if you breach these terms and
                            conditions in any way, NIDP may take such action as
                            NIDP deems appropriate to deal with the breach,
                            including suspending your access to the Service or
                            Software, prohibiting you from accessing the Service
                            or Software, blocking computers using your IP
                            address from accessing the Service or Software,
                            contacting your internet service provider to request
                            that they block your access to the Service or
                            Software and/or bringing court proceedings against
                            you.
                        </p>
                    </div>
                </>
            ) : (
                <> </>
            )}

            <FooterService />
        </>
    );
};

export default AppTerms;
