import React from "react";
import HeroLandingPage from "./components/hero/heroLandingPage";
import { useMediaQuery } from "react-responsive";
import News from "./components/newss/News";
import NewsMobile from "./components/newss/Newsmobile";
import SmallFooter from "../../components/footer/smallFooter";
import Allevents from "./components/events/allevents";

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getEventsList,
    getNewsList,
    getTopNewsList,
} from "../../redux/actions";
import Updates from "./components/updates/Updates";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";

const Media = () => {
    const dispatch = useDispatch();
    var topList = [];
    const [isLoading, setIsLoading] = useState(true);
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });
    const [Event, setEvent] = useState("All");
    // const setTopNews = () => {
    //   newsList.map((listItem, index) => {
    //     if (listItem.pin == 1) {
    //       topList.push(index);
    //     }
    //   });
    // };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        // const timer = setTimeout(() => {
        //   setIsLoading(false);
        // }, 10000);

        // Group the dispatch calls based on their functionality
        const dataDispatches = [
            dispatch(getNewsList()),
            dispatch(getEventsList()),
            dispatch(getTopNewsList()),
        ];

        // Execute the dispatch calls in parallel
        Promise.all(dataDispatches)
            .then(() => {
                setIsLoading(false);
                // Do something after all the dispatch calls have completed
            })
            .catch((error) => {
                // Handle any errors that occur during the dispatch calls
            });

        // return () => {
        //   clearTimeout(timer);
        // };
    }, [dispatch]);

    return (
        <>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <HeroLandingPage />

                    {/* {isDesktopOrLaptop ? <News /> : <NewsMobile />} */}

                    <Updates />

                    <Allevents type={Event} />

                    <SmallFooter />
                </>
            )}
        </>
    );
};
export default Media;
