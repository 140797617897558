import axios from "axios";

export const GET_CAREER_LIST = "GET_CAREER_LIST";

const headers = {
  "Accept": "application/json",
  "Content-Type": "application/json",
  "X-Requested-With": "nid-website",
};

export const getCareerList = () => async (dispatch) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_BASE_URL + `/career/Catgorieslists`,
      {
        headers: headers,
        timeout: 5000,
      }
    );

    if (response && response.data) {
      dispatch({
        type: GET_CAREER_LIST,
        payload: response.data,
      });
      sessionStorage.setItem("careerList", JSON.stringify(response.data));
    } else {
      const cachedCareerList = sessionStorage.getItem("careerList");
      if (cachedCareerList) {
        dispatch({
          type: GET_CAREER_LIST,
          payload: JSON.parse(cachedCareerList),
        });
      }
    }
  } catch (err) {
    const cachedCareerList = sessionStorage.getItem("careerList");
    if (cachedCareerList) {
      dispatch({
        type: GET_CAREER_LIST,
        payload: JSON.parse(cachedCareerList),
      });
    }
  }
};