import React, { useContext, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import FooterService from "../../components/footer/footer";
import TopBar from "../../components/navbar/navbar";
import Sidebar from "../../components/sidebar/sidebar";
import { ViewContext } from "../../providers/viewContext";
import i18next from "i18next";

const FooterCopyright = () => {
    const { view, setView } = useContext(ViewContext);
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            {isDesktopOrLaptop ? (
                <TopBar viewState={view ? "citizen" : "stakeholder"} />
            ) : (
                <Sidebar />
            )}
            {i18next.language === "en" ? (
                <>
                    <div class='bg-faq bg-center bg-cover flex '>
                        <div className='bg-copy-hero-gradient flex h-full w-full '>
                            <div className='flex justify-start text-4xl md:text-8xl  w-full md:pt-56 pt-32 md:pl-40 pl-10'>
                                <h1 className='pr-2 pb-10 font-bold  text-white w-full '>
                                    Copyright Policy
                                </h1>
                            </div>
                        </div>
                    </div>

                    <div class='container mx-auto px-4 py-8 '>
                        <p class='mb-4'>
                            Welcome to the National ID Program (NIDP) government
                            website. This website is managed by the NIDP to
                            provide information and services to the public. The
                            content on this website is subject to copyright
                            protection, and all rights are reserved by the
                            program. This copyright policy outlines the terms
                            and conditions of the use of the content on this
                            website.
                        </p>

                        <h2 class='text-xl font-bold mb-2'>
                            Copyright Ownership
                        </h2>
                        <p class='mb-4'>
                            The NIDP is the copyright owner of all the content
                            on this website, including but not limited to text,
                            images, graphics, logos, audio, and video files.
                            NIDP holds the exclusive right to use, reproduce,
                            distribute, display, modify, and create derivative
                            works from the content on this website.
                        </p>

                        <h2 class='text-xl font-bold mb-2'>
                            Permissible Use of Content
                        </h2>
                        <p class='mb-4'>
                            The content on this website is provided for
                            informational purposes only. You may use the content
                            on this website for personal use only. You are not
                            permitted to use the content on this website for
                            commercial purposes without obtaining prior written
                            consent from NIDP.
                        </p>

                        <h2 class='text-xl font-bold mb-2'>
                            Prohibited Use of Content
                        </h2>
                        <p class='mb-4'>
                            You are prohibited from using the content on this
                            website in any way that violates the NIDP's
                            intellectual property rights. You may not reproduce,
                            distribute, display, modify, transmit, or create
                            derivative works from the content on this website
                            without obtaining prior written consent from NIDP.
                        </p>

                        <h2 class='text-xl font-bold mb-2'>
                            Linking to the NIDP government website
                        </h2>
                        <p class='mb-4'>
                            You may link to the NIDP website's homepage or any
                            other page on this website. However, you may not
                            link to this website in a way that suggests that the
                            NIDP website endorses your website or its content.
                        </p>

                        <h2 class='text-xl font-bold mb-2'>Disclaimer</h2>
                        <p class='mb-4'>
                            The NIDP website makes every effort to ensure the
                            accuracy and reliability of the information provided
                            on this website. However, it does not guarantee the
                            accuracy, completeness, or timeliness of the
                            information on this website. NIDP is not liable for
                            any damages or losses resulting from the use of the
                            content on this website.
                        </p>

                        <h2 class='text-xl font-bold mb-2'>
                            Changes to Copyright Policy
                        </h2>
                        <p class='mb-4'>
                            The NIDP website may revise this copyright policy at
                            any time without prior notice. By using this
                            website, you agree to be bound by the current
                            version of the copyright policy.
                        </p>

                        <h2 class='text-xl font-bold mb-2'>Contact Us</h2>
                        <p class='mb-4'>
                            If you have any questions or concerns regarding this
                            copyright policy, please contact us at
                            info@id.gov.et.
                        </p>
                    </div>
                </>
            ) : (
                <>
                    <div class='bg-faq bg-center bg-cover flex '>
                        <div className='bg-hero-gradient flex h-full w-full '>
                            <div className='flex justify-start text-4xl md:text-8xl  w-full md:pt-56 pt-32 md:pl-40 pl-10'>
                                <h1 className='pr-2 pb-10 font-bold  text-white w-full '>
                                    የቅጂ መብት ፖሊሲ
                                </h1>
                            </div>
                        </div>
                    </div>

                    <div class='container mx-auto px-4 py-8 '>
                        <p class='mb-4'>
                            እንኳን ወደ ብሄራዊ መታወቂያ ፕሮግራም ድህረ ገፅ በደህና መጡ። ይህ ድህረ-ገፅ
                            የፕሮግራሙን መረጃዎች እና አገልግሎቶች ለህዝብ ለማድረስ የተዘጋጀ ድህረ ገፅ ነው።
                            በዚህ ድህረ ገፅ ላይ የተሚገኙ ይዘቶች የቅጂ መብት ጥበቃ ያላቸው ናቸው በመሆኑ
                            ሁሉም የቅጂ መብቶች የፕሮግራሙ ናቸው። ይህ የቅጂ መብት ፖሊሲ በድህረ-ገፁ
                            የተቀመጡት ይዘቶችን አጠቃቀም በተመለከተ ደንብ እና ግዴታዎችን ያስቀምጡ።
                        </p>

                        <h2 class='text-xl font-bold mb-2'>የቅጂ መብት ባለቤትነት </h2>
                        <p class='mb-4'>
                            ብሄራዊ መታወቂያ ፕሮግራም በዚህ ድህረ ገፅ የሚገኙ ማናቸውም ፅሁፎች፣ ምስሎች፣
                            ግራፊክሶች፣ ምልክቶች፣ የድምፅ እና የምስል ፋይሎች ባለቤት ነው። ብሄራዊ መታወቂያ
                            ፕሮግራም እነዚህ ይዘቶችን የመጠቀም፣ የማባዛት፣ የማሰራጨት፣ የማሳየት፣ የማሻሻል
                            እና ተጓዳኝ ስራዎችን የመፍጠር መብት አለው።.
                        </p>

                        <h2 class='text-xl font-bold mb-2'>
                            የተፈቀደ ይዘቶችን መጠቀም{" "}
                        </h2>
                        <p class='mb-4'>
                            በዚህ ድህረ-ገፅ የሚገኙት ይዘቶች ለመረጃ ብቻ የሚያገለግሉ ብቻ ናቸው። በዚህ
                            ድህረ-ገፅ የሚገኙትን ይዘቶች ለግል እና ለንግድ ላለሆነ አላማ መጠቀም ይቻላል።
                            ነገር ግን፣ የብሄራዊ መታወቂያ ፕሮግራምን የፅሁፍ ፍቃድ ሳያገኙ ይዘቶቹን ለንግድ
                            አላማ መጠቀም አይቻልም።
                        </p>

                        <h2 class='text-xl font-bold mb-2'>የተከለከለ አጠቃቀም</h2>
                        <p class='mb-4'>
                            ይህን ድህረ ገፅ የብሄራዊ መታወቂያ ፕሮግራምን አእምሯዊ ንብረት በሚጎዳ መልኩ
                            መጠቀም የተከለከለ ነው። ብሄራዊ መታወቂያ ፕሮግራም ቅድሚ የፅሁፍ ፍቃድ ሳያገኙ
                            ይዘቶቹን ማባዛት፣ ማሰራጨት፣ ማቅረብ፣ ማስተካከል፣ ማስተላለፍ ወይም ተጓዳኝ
                            ስራዎች መፍጠር አይቻልም።
                        </p>

                        <h2 class='text-xl font-bold mb-2'>
                            ከብሄራዊ መታወቂያ ፕሮግራም ጋር ማገናኘት
                        </h2>
                        <p class='mb-4'>
                            ድህረ ገፆን ከዚህ ድህረ ገፅ ጋር ማገናኘት ይችላሉ። ነገር ግን፣ ከዚህ ድህረ ገፅ
                            ጋር የእርሶን ይዘት ወይም ድህረ ገፅ በሚያገናኙ ግዜ ብሄራዊ መታወቂያ ፕሮግራም
                            የእርሶን ይዘት እደሚደግፍ አድርገው ማቅረብ አይችሉም።
                        </p>

                        <h2 class='text-xl font-bold mb-2'>ዋስትና የማይሰጥ ስለመሆኑ</h2>
                        <p class='mb-4'>
                            የብሄራዊ መታወቂያ ፕሮግራም ድህረ ገጽ በዚህ ድህረ ገጽ ላይ የቀረበውን መረጃ
                            ትክክለኛነት እና አስተማማኝነት ለማረጋገጥ የተቻለውን ሁሉ ጥረት ያደርጋል። ይሁን
                            እንጂ በዚህ ድረ-ገጽ ላይ ያለውን መረጃ ትክክለኛነት፣ ሙሉነት ወይም ወቅታዊነትን
                            በተመለከተ ዋስትና አይሰጥም። የብሄራዊ መታወቂያ ፕሮግራም በዚህ ድረ-ገጽ ላይ
                            ባለው ይዘት አጠቃቀም ምክንያት ለሚደርስ ማንኛውም ጉዳት ወይም ኪሳራ ተጠያቂ
                            አይሆንም።
                        </p>

                        <h2 class='text-xl font-bold mb-2'>የቅጂ መብት ፖሊሲ ለውጦች</h2>
                        <p class='mb-4'>
                            የየብሄራዊ መታወቂያ ፕሮግራም ድህረ ገጽ ይህንን የቅጂ መብት ፖሊሲ በማንኛውም ጊዜ
                            ያለቅድመ ማስታወቂያ ሊያሻሽለው ይችላል። ይህን ድህረ ገጽ በመጠቀም፣ አሁን ባለው
                            የቅጂ መብት ፖሊሲ ለመገዛት ተስማምተዋል።
                        </p>

                        <h2 class='text-xl font-bold mb-2'>አድራሻ</h2>
                        <p class='mb-4'>
                            ይህንን የቅጂ መብት ፖሊሲ በተመለከተ ማንኛቸውም ጥያቄዎች ወይም ስጋቶች ካሉዎት፣
                            እባክዎን በ info@id.gov.et ይፃፉልን።
                        </p>
                    </div>
                </>
            )}
            <FooterService />
        </>
    );
};

export default FooterCopyright;
