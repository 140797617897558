import React, { useState } from "react";
import HeroLandingPage from "./components/hero/heroLandingPage";
import { useMediaQuery } from "react-responsive";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGalleries } from "../../redux/selectors";
import GalleryComponent from "./components/galleryComponent/galleryComponent";
import SmallFooter from "../../components/footer/smallFooter";
import GalleryComponentMobile from "./components/galleryComponentMobile/galleryComponentMobile";
import { getGalleryList } from "../../redux/actions";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";
import Video from "./components/Video/Video";

// import Allevents from "../media/components/events/allevents";

const Gallery = ({ title }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });
    // const [Event, setEvent] = useState("Latest");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        // const timer = setTimeout(() => {
        //   setIsLoading(false);
        // }, 10000);

        // Group the dispatch calls based on their functionality
        const dataDispatches = [dispatch(getGalleryList())];

        // Execute the dispatch calls in parallel
        Promise.all(dataDispatches)
            .then(() => {
                setIsLoading(false);
                // Do something after all the dispatch calls have completed
            })
            .catch((error) => {
                // Handle any errors that occur during the dispatch calls
            });

        // return () => {
        //   clearTimeout(timer);
        // };
    }, [dispatch]);

    return (
        <>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <HeroLandingPage />

                    {isDesktopOrLaptop ? (
                        <>
                            <GalleryComponent title={title} />
                        </>
                    ) : (
                        <>
                            <GalleryComponentMobile />
                        </>
                    )}

                    <SmallFooter />
                </>
            )}
        </>
    );
};
export default Gallery;
