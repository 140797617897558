import { MdOutlineDownload } from "react-icons/md";
import authentication from "../../../../assets/authentication-29.png";
import authenticationWhite from "../../../../assets/authentication-white.png";

import ReactPlayer from "react-player/lazy";
import background from "../../../../assets/10-01.jpg";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import offline from "../../../../assets/authentication/white-02.png";
import online from "../../../../assets/authentication/white-01.png";
import darkoffline from "../../../../assets/authentication/dark-01-02.png";
import Modal from "../../../../components/Modal/modal";
import { RiCloseCircleLine } from "react-icons/ri";

const Body = () => {
    const { t } = useTranslation();
    const [openModal, setOpenModal] = useState(false);
    const [showImage, setShowImage] = useState(null);
    console.log(showImage);
    const [videoPlayingIndex, setVideoPlayingIndex] = useState(null);
    const authenticationConfig = [
        {
            title: "auth-title-1",
            descritpion: "auth-description-1",
            pdfLink:
                "https://drive.google.com/file/d/1CE8nBTB3LGD3VzWitAy7oY4FW_NOiXCl/view?usp=sharing",
            small: "auth-description-1-ie",
            VideoLink: "https://www.youtube.com/watch?v=zyIUUAhKUVo",
        },
        {
            title: "auth-title-2",
            descritpion: "auth-description-2",
            pdfLink:
                "https://drive.google.com/file/d/1i_8MWLixqvi9qARSkE4Nv0CBnPwUyvBc/view?usp=sharing",
            small: "auth-description-2-ie",
            VideoLink: "https://www.youtube.com/watch?v=LnxQKFxXEYQ",
            image: darkoffline,
        },
        {
            title: "auth-title-3",
            descritpion: "auth-description-3",
            pdfLink:
                "https://drive.google.com/file/d/1i_8MWLixqvi9qARSkE4Nv0CBnPwUyvBc/view?usp=sharing",
            small: "auth-description-3-ie",
            VideoLink: "https://www.youtube.com/watch?v=LnxQKFxXEYQ",
            image: online,
        },
        {
            title: "auth-title-4",
            descritpion: "auth-description-4",
            pdfLink:
                "https://docs.google.com/presentation/d/1GhzeTdpDHNBi6ckxx98F_3b8fr5vq3nTdIyZdVL4X2o/edit#slide=id.g2c1f5f55582_1_234",
        },
    ];
    const handleCloseModal = () => {
        setOpenModal(false);
        setShowImage(null);
    };
    const handleOpenMModal = (imageToShow) => {
        setOpenModal(true);
        setShowImage(imageToShow);
    };

    return (
        <div className='flex flex-col justify-center items-center '>
            <div className='w-full bg-white'>
                <div className='w-full'>
                    <div className=' w-full py-8 md:py-16 px-5 '>
                        <div className='max-w-[800px] w-full text-black mx-auto space-y-2 mb-5'>
                            <div className='flex items-center justify-center gap-4 '>
                                <div
                                    className={`flex items-center justify-center max-w-[40px] max-h-[40px] md:max-w-[50px] md:max-h-[50px] overflow-clip rounded-full ring-2 ring-white place-self-end md:place-self-start`}
                                >
                                    <img
                                        src={authentication}
                                        className='p-1 object-cover'
                                    />
                                </div>
                                <p
                                    className={`text-3xl font-semibold text-center `}
                                >
                                    {t(authenticationConfig[1].title)}
                                </p>
                            </div>
                            <p
                                className={` w-full text-left text-lg md:text-2xl justify-center`}
                            >
                                {t(authenticationConfig[1].descritpion)}
                            </p>
                            <p className='text-lg md:text-2xl w-full text-center mb-2 pb-2'>
                                {t(authenticationConfig[1].small)}
                            </p>
                            <div className='flex justify-center pt-5 pb-5'>
                                <a
                                    href={authenticationConfig[1].pdfLink}
                                    download
                                    className='w-fit'
                                >
                                    <p className='flex items-center justify-center  gap-2 w-fit px-4 text-white py-1  hover:scale-110 hover:bg-[#083247] bg-[#47F4C7] duration-300 text-xl rounded-md'>
                                        {t("auth-download")}
                                        <MdOutlineDownload />
                                    </p>
                                </a>
                            </div>
                        </div>
                        <div className='w-full grid lg:grid-cols-2 place-items-center max-w-[1600px] mx-auto gap-5'>
                            <div
                                className={`flex w-full gap-5 flex-row-reverse`}
                            >
                                <div
                                    className={`flex flex-col w-full md:justify-end md:items-end md:text-right text-white`}
                                >
                                    <div
                                        className={`place-self-end rounded-lg overflow-clip aspect-video drop-shadow-xl shadow-md shadow-MetallicBlue w-full max-w-[600px]`}
                                    >
                                        <ReactPlayer
                                            url={
                                                authenticationConfig[1]
                                                    .VideoLink
                                            }
                                            width='100%'
                                            height='100%'
                                            controls={true}
                                            onPlay={() =>
                                                setVideoPlayingIndex("")
                                            }
                                            playing={false}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className='w-full order-first h-full cursor-zoom-in '
                                onClick={() =>
                                    handleOpenMModal(
                                        authenticationConfig[1].image
                                    )
                                }
                            >
                                <div className='flex w-full'>
                                    <img
                                        src={authenticationConfig[1].image}
                                        className='w-full max-w-[1000px] aspect-video mx-auto'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className='w-full'
                    style={{
                        backgroundImage: `url(${background})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                    }}
                >
                    <div className='w-full py-8 md:py-16 bg-about-pilot px-5'>
                        <div className='w-full grid lg:grid-cols-2 place-items-center max-w-[1600px] mx-auto gap-5'>
                            <div className={`flex w-full gap-5 flex-row`}>
                                <div
                                    className={`md:flex hidden items-center justify-center w-full h-full max-w-[40px] max-h-[40px] md:max-w-[50px] md:max-h-[50px] overflow-clip rounded-full ring-2 
                                 ring-white md:place-self-start`}
                                >
                                    <img
                                        src={authentication}
                                        className='p-1 object-cover'
                                    />
                                </div>
                                <div
                                    className={`space-y-5 flex flex-col w-full md:justify-end md:items-end md:text-left text-white`}
                                >
                                    <div className='flex items-center gap-3 w-full '>
                                        <div
                                            className={`flex md:hidden items-center justify-center max-w-[40px] max-h-[40px] md:max-w-[50px] md:max-h-[50px] overflow-clip rounded-full ring-2 ring-white place-self-end md:place-self-start`}
                                        >
                                            <img
                                                src={authentication}
                                                className='p-1 object-cover'
                                            />
                                        </div>
                                        <p
                                            className={`text-3xl font-semibold text-white w-full `}
                                        >
                                            {t(authenticationConfig[2].title)}
                                        </p>
                                    </div>
                                    <p
                                        className={`text-left w-full text-lg md:text-2xl`}
                                    >
                                        {t(authenticationConfig[2].descritpion)}
                                    </p>
                                </div>
                            </div>
                            <div
                                className='w-full'
                                onClick={() =>
                                    handleOpenMModal(
                                        authenticationConfig[1].image
                                    )
                                }
                            >
                                <div className='flex w-full '>
                                    <img
                                        src={authenticationConfig[2].image}
                                        className='w-full max-w-[1000px] aspect-video mx-auto'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='w-full py-8 md:py-16 '>
                    <div className='w-full grid lg:grid-cols-2 place-items-center max-w-[1600px] mx-auto gap-5'>
                        <div className={`flex w-full gap-5 flex-row-reverse`}>
                            <div
                                className={`md:flex hidden items-center justify-center w-full h-full max-w-[40px] max-h-[40px] md:max-w-[50px] md:max-h-[50px] overflow-clip rounded-full ring-2 
                                 ring-white md:place-self-start`}
                            >
                                <img
                                    src={authentication}
                                    className='p-1 object-cover'
                                />
                            </div>
                            <div
                                className={`space-y-5 flex flex-col w-full md:justify-end md:items-end md:text-left text-black`}
                            >
                                <div className='flex items-center gap-3 justify-center'>
                                    <div
                                        className={`flex md:hidden items-center justify-center max-w-[40px] max-h-[40px] md:max-w-[50px] md:max-h-[50px] overflow-clip rounded-full ring-2 ring-white place-self-end md:place-self-start`}
                                    >
                                        <img
                                            src={authentication}
                                            className='p-1 object-cover'
                                        />
                                    </div>
                                    <p
                                        className={`text-3xl font-semibold text-black`}
                                    >
                                        {t(authenticationConfig[0].title)}
                                    </p>
                                </div>
                                <p
                                    className={`text-center lg:text-right text-lg md:text-2xl`}
                                >
                                    {t(authenticationConfig[0].descritpion)}
                                </p>

                                <p className='text-center text-lg md:text-2xl'>
                                    {t(authenticationConfig[0].small)}
                                </p>

                                <div className='w-fit mx-auto lg:mx-0'>
                                    <a
                                        href={authenticationConfig[0].pdfLink}
                                        download
                                    >
                                        <p className='flex items-center justify-center  gap-2 w-fit px-4 text-white py-1  hover:scale-110 hover:bg-[#083247] bg-[#47F4C7] duration-300 text-xl rounded-md'>
                                            {t("auth-download")}
                                            <MdOutlineDownload />
                                        </p>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='w-full lg:order-first '>
                            <div
                                className={`mx-auto rounded-lg overflow-clip aspect-video drop-shadow-xl shadow-md shadow-MetallicBlue w-full max-w-[600px]`}
                            >
                                <ReactPlayer
                                    url={authenticationConfig[0].VideoLink}
                                    width='100%'
                                    height='100%'
                                    controls={true}
                                    onPlay={() => setVideoPlayingIndex("")}
                                    playing={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className='w-full'
                    style={{
                        backgroundImage: `url(${background})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                    }}
                >
                    <div className='bg-about-pilot w-full py-8 md:py-16 px-5'>
                        <div className='w-full grid lg:grid-cols-2 place-items-center max-w-[1600px] mx-auto gap-5'>
                            <div className={`flex w-full gap-5`}>
                                <div
                                    className={`md:flex hidden items-center justify-center w-full h-full max-w-[40px] max-h-[40px] md:max-w-[50px] md:max-h-[50px] overflow-clip rounded-full ring-2 
                                 ring-white md:place-self-start`}
                                >
                                    <img
                                        src={authentication}
                                        className='p-1 object-cover'
                                    />
                                </div>
                                <div
                                    className={`space-y-5 flex flex-col w-full md:justify-start md:items-start md:text-start text-white`}
                                >
                                    <div className='flex items-center gap-3 '>
                                        <div
                                            className={`flex md:hidden items-center justify-center max-w-[40px] max-h-[40px] md:max-w-[50px] md:max-h-[50px] overflow-clip rounded-full ring-2 ring-white place-self-end md:place-self-start`}
                                        >
                                            <img
                                                src={authentication}
                                                className='p-1 object-cover'
                                            />
                                        </div>
                                        <p
                                            className={`text-3xl font-semibold text-white`}
                                        >
                                            {t(authenticationConfig[3].title)}
                                        </p>
                                    </div>
                                    <p className={`text-lg md:text-2xl`}>
                                        {t(authenticationConfig[3].descritpion)}
                                    </p>
                                    {
                                        <p className='text-lg md:text-2xl'>
                                            {t(authenticationConfig[3].small)}
                                        </p>
                                    }
                                </div>
                            </div>
                            <div className='w-full'>
                                <div className='flex w-full items-center justify-center'>
                                    <a
                                        href={authenticationConfig[3].pdfLink}
                                        download
                                    >
                                        <p className='flex items-center justify-center  gap-2 w-fit px-4 text-white py-1  hover:scale-110 hover:bg-[#083247] bg-[#47F4C7] duration-300 text-xl rounded-md'>
                                            {t("auth-download")}
                                            <MdOutlineDownload />
                                        </p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal handleClose={handleCloseModal} open={openModal}>
                <div className='max-w-[1400px] w-full mx-auto bg-white aspect-video relative'>
                    <button
                        type='button'
                        onClick={handleCloseModal}
                        className='place-self-end p-2 absolute top-20 z-50 inset-x-10 text-black'
                    >
                        <RiCloseCircleLine className='text-black/90 hover:text-black text-2xl' />
                    </button>
                    <img
                        src={showImage}
                        className='w-full h-full object-contain'
                    />
                </div>
            </Modal>
            {/* {authenticationConfig.map((authItem, index) => (
                <div
                    key={authItem.title}
                    className='w-full'
                    style={{
                        backgroundImage:
                            index % 2 !== 0 ? `url(${background})` : "",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                    }}
                >
                    <div
                        className={`${
                            index % 2 !== 0 && " bg-about-pilot"
                        } w-full  py-8 md:py-16 flex justify-center`}
                    >
                        <div
                            className={`flex flex-col lg:flex-row w-full items-center justify-center gap-24 px-5 md:px-10 lg:px-24 2xl:px-36 max-w-[1800px] py-10 ${"lg:flex-row-reverse text-white"}`}
                        >
                            <div
                                className={`flex w-full gap-5 ${
                                    index % 2 !== 0 ? "flex-row-reverse" : ""
                                } `}
                            >
                                <div
                                    className={`md:flex hidden items-center justify-center w-full h-full max-w-[40px] max-h-[40px] md:max-w-[50px] md:max-h-[50px] overflow-clip rounded-full ring-2 ${
                                        index % 2 !== 0
                                            ? "ring-white md:place-self-start"
                                            : "ring-MetallicBlue"
                                    }`}
                                >
                                    <img
                                        src={
                                            index % 2 === 0
                                                ? authentication
                                                : authenticationWhite
                                        }
                                        className='p-1 object-cover'
                                    />
                                </div>
                                <div
                                    className={`space-y-5 flex flex-col w-full ${
                                        index % 2 !== 0
                                            ? "md:justify-end md:items-end md:text-right"
                                            : ""
                                    }`}
                                >
                                    <div className='flex items-center gap-3 '>
                                        <div
                                            className={`flex md:hidden items-center justify-center max-w-[40px] max-h-[40px] md:max-w-[50px] md:max-h-[50px] overflow-clip rounded-full ring-2 ${
                                                index % 2 !== 0
                                                    ? "ring-white place-self-end md:place-self-start"
                                                    : "ring-MetallicBlue"
                                            }`}
                                        >
                                            <img
                                                src={
                                                    index % 2 === 0
                                                        ? authentication
                                                        : authenticationWhite
                                                }
                                                className='p-1 object-cover'
                                            />
                                        </div>
                                        <p
                                            className={`text-3xl font-semibold ${
                                                index % 2 === 0
                                                    ? "text-MetallicBlue"
                                                    : "text-white"
                                            }`}
                                        >
                                            {t(authItem.title)}
                                        </p>
                                    </div>
                                    <p
                                        className={`${
                                            index % 2 == 0
                                                ? " text-right"
                                                : "text-left"
                                        }text-lg md:text-2xl`}
                                    >
                                        {t(authItem.descritpion)}
                                    </p>
                                    {index !== 2 ? (
                                        <p className='text-lg md:text-2xl'>
                                            {t(authItem.small)}
                                        </p>
                                    ) : (
                                        <></>
                                    )}
                                    {index === 1 ? (
                                        <div
                                            className={`mx-auto rounded-lg overflow-clip border-4 min-h-[180px] w-full aspect-video border-[#e0e0e0] `}
                                        >
                                            <ReactPlayer
                                                url={authItem.VideoLink}
                                                width='100%'
                                                height='100%'
                                                controls={true}
                                                onPlay={() =>
                                                    setVideoPlayingIndex(index)
                                                }
                                                playing={
                                                    index !== null &&
                                                    index === videoPlayingIndex
                                                        ? true
                                                        : false
                                                }
                                            />
                                        </div>
                                    ) : null}
                                    {index !== 3 && index !== 2 && (
                                        <a
                                            href={authItem.pdfLink}
                                            download
                                            className='flex items-center justify-center  gap-2 w-fit px-4 text-white py-1 hover:bg-[#083247] bg-[#47F4C7] duration-300 hover:scale-110 text-xl rounded-md'
                                        >
                                            {t("auth-download")}
                                            <MdOutlineDownload />
                                        </a>
                                    )}
                                </div>{" "}
                            </div>
                            <div className='w-full min-w-fit lg:max-w-[37%]'>
                                <div
                                    className={`
                                    mx-auto
                                    rounded-lg
                                    overflow-clip 
                                    ${
                                        index !== 2 || index !== 1
                                            ? index === 2
                                                ? "border-4 min-h-[180px] aspect-video border-[#e0e0e0] bg-MetallicBlue"
                                                : index === 0
                                                ? "aspect-video h-fit drop-shadow-xl shadow-md shadow-MetallicBlue w-full"
                                                : "w-fit h-fit drop-shadow-xl shadow-md shadow-MetallicBlue"
                                            : "w-fit h-fit drop-shadow-xl shadow-md shadow-MetallicBlue"
                                    }
                                    `}
                                >
                                    {index !== 2 && index !== 1 ? (
                                        <ReactPlayer
                                            url={authItem.VideoLink}
                                            width='100%'
                                            height='100%'
                                            controls={true}
                                            onPlay={() =>
                                                setVideoPlayingIndex(index)
                                            }
                                            playing={
                                                index !== null &&
                                                index === videoPlayingIndex
                                                    ? true
                                                    : false
                                            }
                                        />
                                    ) : index === 1 || index === 2 ? (
                                        <div className='flex w-full'>
                                            <img
                                                src={authItem.image}
                                                className='w-full max-w-[600px] aspect-video'
                                            />
                                        </div>
                                    ) : (
                                        <a href={authItem.pdfLink} download>
                                            <p className='flex items-center justify-center  gap-2 w-fit px-4 text-white py-1  hover:scale-110 hover:bg-[#083247] bg-[#47F4C7] duration-300 text-xl rounded-md'>
                                                {t("auth-download")}
                                                <MdOutlineDownload />
                                            </p>
                                        </a>
                                    )}
                                    {index === 3 ? (
                                        <a href={authItem.pdfLink} download>
                                            <p className='flex items-center justify-center  gap-2 w-fit px-4 text-white py-1  hover:scale-110 hover:bg-[#083247] bg-[#47F4C7] duration-300 text-xl rounded-md'>
                                                {t("auth-download")}
                                                <MdOutlineDownload />
                                            </p>
                                        </a>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}{" "} */}
        </div>
    );
};

export default Body;
