import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";

import HandImg from "../../../../assets/id-card.png";

import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Parallax } from "react-scroll-parallax";

const Idandmobile = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });
    const [isOpen, setIsOpen] = useState(false);

    const closeModal = () => {
        setIsOpen(false);
    };

    const openModal = () => {
        setIsOpen(true);
    };

    return (
        //
        <>
            {isDesktopOrLaptop ? (
                <div class=' text-white  bg-update-services bg-left-top bg-cover h-fit w-full'>
                    <div className='flex flex-col pb-10 bg-black/40'>
                        <div
                            id='get-card'
                            className='flex gap-20 items-center '
                        >
                            <div className='flex-1'>
                                <img
                                    src={HandImg}
                                    alt='Hand Holding Card'
                                    className='w-full h-auto object-contain drop-shadow-lg'
                                />
                            </div>

                            <div className='flex flex-col items-start mt-16 flex-1 pr-32'>
                                <h1 className='text-6xl font-semibold '>
                                    {t("services-pre-reg-text")}
                                </h1>

                                <p className='text-2xl font-normal text-justify mt-5 '>
                                    {t("services-pre-reg-text2")}{" "}
                                </p>

                                <div className='flex gap-10 text-xl my-20'>
                                    <Link
                                        to='/cardprint'
                                        className='w-full text-center px-20 font-semibold text-white/90 shadow-md border-2  border-white/70 py-2 bg-transparent  rounded-3xl  hover:bg-white hover:text-MetallicBlue transition-all transform duration-300'
                                    >
                                        {t("services-card-order-button")}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                //************************************************************* Mobile Version *************************************************************
                <div class='text-white  bg-update-services  bg-cover  w-full '>
                    <div className='flex flex-col bg-black/40'>
                        <div id='pre-reg' className='flex px-10 pt-10'>
                            <div className='flex flex-col items-center justify-center py-0 gap-5 mx-auto'>
                                <h1 className='text-4xl font-semibold '>
                                    {t("services-pre-reg-text")}
                                </h1>
                                <div className='text-lg font-normal text-justify w-full max-w-[600px] mx-auto lg:max-w-max'>
                                    {t("services-pre-reg-text2")}{" "}
                                </div>

                                <div className='flex flex-col pt-5 text-xl'>
                                    <Link
                                        to='/cardprint'
                                        className='my-2 text-lg w-full text-center px-10 md:px-20 font-semibold text-white/90 shadow-md border-2  border-white/70 py-2 bg-transparent  rounded-3xl  hover:bg-[#083247] transition-all transform duration-300'
                                    >
                                        {t("services-card-order-button")}
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className='flex items-center justify-center w-full'>
                            <img
                                src={HandImg}
                                alt='Logo'
                                className='items-start align-top'
                            />
                        </div>
                    </div>
                </div>
            )}
            {isOpen && (
                <div
                    className='fixed inset-0 flex items-center justify-center backdrop-blur-[2px] bg-black/75 bg-opacity-50 z-50 transition-all duration-500'
                    onClick={closeModal}
                >
                    <div
                        className='fixed inset-0 flex items-center justify-center bg-black/75 bg-opacity-50 z-50 transition-all duration-500'
                        onClick={closeModal}
                    >
                        <div
                            className='bg-white rounded-lg p-4 w-full max-w-2xl  lg:p-8 mx-3 flex flex-col justify-between' // Added flex flex-col justify-between here
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div className='flex flex-col lg:flex-row w-full h-full text-2xl text-white'>
                                <div className='flex flex-col w-full lg:w-1/2 h-full items-center px-2 lg:px-5 py-4 flex-grow'>
                                    <div className='text-MetallicBlue text-lg lg:text-xl text-center mb-4 lg:mb-10'>
                                        {t("landing-locations")}
                                    </div>
                                </div>
                            </div>

                            <div className='flex flex-col lg:flex-row w-full h-full text-2xl text-white mt-4'>
                                <div className='flex flex-col w-full lg:w-1/2 h-full items-center lg:ml-36 px-2 lg:px-5 py-4'>
                                    <a href='/locations'>
                                        <button className='bg-MetallicBlue px-4 lg:px-8 py-2 rounded-lg hover:bg-green hover:text-MetallicBlue'>
                                            {t("landing-locations-button")}
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Idandmobile;
