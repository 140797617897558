import React, { useState, useEffect, useRef, Suspense } from "react";
import Iframe from "react-iframe";
import { useMediaQuery } from "react-responsive";
import { FaChevronRight } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { Carousel } from "react-responsive-carousel";
// import TextTruncate from "react-text-truncate";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import TestImg from "../../../../assets/Automated-Validation-01-1.jpg";
import parser from "react-html-parser";
import { useSelector } from "react-redux";
import { getGalleries } from "../../../../redux/selectors";
import Video from "../Video/Video";
const LazyIframe = React.lazy(() => import("./LazyIframe"));

const GalleryComponent = ({ title }) => {
    const list = useSelector(getGalleries);
    console.log(list.length);
    const one = useMediaQuery({
        query: "(max-height: 300px)",
    });

    const TwoFiveZeroPercent = useMediaQuery({
        query: "(max-height: 400px)",
    });

    const TwoZeroZeroPercent = useMediaQuery({
        query: "(max-height: 500px)",
    });

    const OneSevenFivePercent = useMediaQuery({
        query: "(max-height: 600px)",
    });

    const OneFivePercent = useMediaQuery({
        query: "(max-height: 700px)",
    });

    const OneTwoFivePercent = useMediaQuery({
        query: "(max-height: 800px)",
    });

    const OneOneTenPercent = useMediaQuery({
        query: "(max-height: 900px)",
    });
    const [IsOpen, setIsOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState("");
    const [currentCaption, setCurrentCaption] = useState("");
    const imgRef = useRef(null);
    const [VidOpen, setVidOpen] = useState(false);
    const [vidModal, setVidModal] = useState("");
    const [image, setImage] = useState(true);
    const [video, setVideo] = useState(true);
    const [lists, setLists] = useState([]);
    const [picturesLists, setPicturesLists] = useState([]);
    const [videoLists, setVideoLists] = useState([]);
    // console.log(JSON.stringify(videoLists));
    const [itemValue, setItemValue] = useState(0);
    const [activeButtonIndex, setActiveButtonIndex] = useState(
        title === "Pictures" ? 1 : title === "Videos" ? 2 : 1
    );
    const [pictureType, setPictureType] = useState("Field Work");

    const { t } = useTranslation();
    const navigate = useNavigate();
    const subPath = useLocation();
    const [Event, setEvent] = useState(title);
    const [pictureListFiltered, setPictureListFiltered] = useState(null);

    const flattenArray = (pictureListFiltered) => {
        let flattenedArray = [];
        for (let i = 0; i < pictureListFiltered.length; i++) {
            for (let j = 0; j < pictureListFiltered[i].length; j++) {
                flattenedArray.push(pictureListFiltered[i][j]);
            }
        }
        flattenedArray = [].concat(...pictureListFiltered);

        flattenedArray = pictureListFiltered.reduce(
            (acc, arr) => acc.concat(arr),
            []
        );

        return flattenedArray;
        // using this to flatten the array from picturelist
    };
    const handleChunk = (pictureFilteredList, chunkSize) => {
        const PicList = [];
        console.log("filtered list", pictureFilteredList);
        for (let i = 0; i < list.length; i += chunkSize) {
            const splitImageChunk = pictureFilteredList.slice(i, i + chunkSize);

            if (splitImageChunk.length > 0) {
                PicList.push(splitImageChunk);
            }
        }
        return PicList;
        // create a chunk after flattening the array
    };
    useEffect(() => {
        const pictureListFiltered = picturesLists
            ?.map((item) =>
                item.filter((image) => image.image_type === pictureType)
            )
            .filter((item) => item.length !== 0);

        console.log(
            picturesLists
                ?.map((item) =>
                    item.filter((image) => image.image_type === "Tele")
                )
                .filter((item) => item.length !== 0)
        );
        // flatten the arry firrt because
        const flattenedArray = flattenArray(pictureListFiltered);
        // chunkify the falltened array into chunks of 4
        const chunkified = handleChunk(flattenedArray, 4);
        setPictureListFiltered(chunkified);
    }, [pictureType, picturesLists]);

    const turnicate = (changeString) => {
        const changedString =
            changeString.length > 120
                ? changeString.slice(0, 120) + "... Read more"
                : changeString;
        return changedString;
    };
    const [LatestContent, setLatestContent] = useState(false);
    const [carouselSelectedIndex, setCarouselSelectedIndex] = useState(0);
    useEffect(() => {
        if (imgRef.current) {
            const imgWidth = imgRef.current.getBoundingClientRect().width;
            const modalContent = document.getElementById("modal-content");
            modalContent.style.width = `${imgWidth}px`;
        }
    }, [currentImage]);

    function toggleModal(imageUrl, caption) {
        setIsOpen(!IsOpen);
        setCurrentImage(imageUrl);
        setCurrentCaption(caption);
    }

    function toggleVidModal(vidUrl) {
        setVidOpen(!VidOpen);
        setVidModal(vidUrl);
    }

    function renderPattern(index) {
        if (index >= list.length) {
            return null; // Exit recursion when all data has been processed
        }
    }

    function splitter(list, chunkSize) {
        // console.log("THIS IS MY ORIGINAL LIST", list);
        let chunk = [];
        let TotalChunk = [];
        let videoChunk = [];
        let imageChunk = [];
        const allList = [];
        const PicList = [];
        const vidList = [];

        chunk = list.slice(0, list.length);
        TotalChunk = list.filter(
            (item) => item.type === "Image" || item.type === "Video"
        );
        imageChunk = chunk.reverse().filter((item) => item.type === "Image");
        videoChunk = chunk.reverse().filter((item) => item.type === "Video");

        for (let i = 0; i < list.length; i += chunkSize) {
            const allChunk = TotalChunk.slice(i, i + chunkSize);
            const splitImageChunk = imageChunk.slice(i, i + chunkSize);
            const splitVideoChunk = videoChunk.slice(i, i + chunkSize);

            if (splitImageChunk.length > 0) {
                PicList.push(splitImageChunk);
            }

            if (splitVideoChunk.length > 0) {
                vidList.push(splitVideoChunk);
            }

            if (allChunk.length > 0) {
                allList.push(allChunk);
            }
        }
        setPicturesLists(PicList);
        setVideoLists(vidList);
        setLists(allList);
    }

    function latest() {
        // setLatestContent(true);
        setActiveButtonIndex(4);
        setEvent("All");
        scrollToFirstItem();
    }

    function scrollToFirstItem() {
        setCarouselSelectedIndex(0);
    }

    useEffect(() => {
        list.length > 0 ? splitter(list, 4) : "";
    }, []);

    const [scrollPos, setScrollPos] = useState(0);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [documentHeight, setDocumentHeight] = useState(0);

    const handleScroll = () => {
        const maxScroll = document.documentElement.scrollHeight - 900; // Calculate the maximum scroll position
        const currentPosition = window.scrollY;
        const clampedScrollPos = Math.max(
            400,
            Math.min(maxScroll, currentPosition)
        ); // Clamp the scroll position between 0 and maxScroll
        setScrollPos(clampedScrollPos);
    };

    const handleResize = () => {
        setWindowHeight(window.innerHeight);
    };

    const clickHandler = () => {
        Event == "Pictures" ? clickHandler : "";
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <>
            {IsOpen && (
                <div
                    className='fixed top-0 left-0 w-full h-full py-20 bg-black bg-opacity-50 flex items-center justify-center z-50 transition-all duration-500'
                    onClick={toggleModal}
                >
                    <img
                        ref={imgRef}
                        src={process.env.REACT_APP_BASE_URL + currentImage}
                        className='h-full object-contain'
                        alt='Modal content'
                    />
                    <div
                        id='modal-content'
                        className='absolute bottom-20 py-20 px-5 bg-news-gradient'
                    >
                        <p className='text-white text-3xl z-50'>
                            {parser(currentCaption)}
                        </p>
                    </div>
                </div>
            )}

            {VidOpen && (
                <div
                    className='fixed top-0 left-0 w-full h-full py-20 bg-black bg-opacity-50 flex items-center justify-center z-50 transition-all duration-500'
                    onClick={toggleVidModal}
                >
                    <div className='relative w-full h-full px-2 flex hover:cursor-pointer hover:scale-105 transition-all duration-500 items-center justify-center'>
                        <Iframe
                            url={vidModal}
                            id=''
                            className='flex w-[80%] h-full'
                            display='block'
                            position='relative'
                        />
                    </div>
                </div>
            )}

            <div className='' key='gallery-component'>
                <div className='flex px-10 mt-16'>
                    <div className='w-[12.4%]'>
                        <h2 className='text-3xl font-semibold  '>Categories</h2>
                    </div>

                    <div className='text-lg font-medium  w-[80.5%] mt-2'>
                        <button className=' bg-button-gradient text-white shadow-2xl rounded-sm px-16'>
                            <h1>Gallery</h1>
                        </button>
                        <button
                            className={`${
                                activeButtonIndex === 4 ? "underline" : ""
                            } content-center px-6 hover:cursor-pointer hover:underline`}
                            onClick={latest}
                        >
                            {t("Latest")}
                        </button>
                        <hr className='w-[95%] border-MetallicBlue border ' />
                    </div>
                </div>

                <div class=' flex backdrop-brightness-95 pl-8 pr-5 pb-10 my-10'>
                    <div className='flex w-[18%] mt-12'>
                        <div className='flex w-[70%] flex-col gap-14 font-semibold text-MetallicBlue '>
                            <div
                                onClick={() => {
                                    setActiveButtonIndex(0);
                                    setEvent("All");
                                }}
                                className={`${
                                    activeButtonIndex === 0 ? "bg-green" : ""
                                }
                  flex content-center px-3 py-2 justify-center text-xl hover:bg-green rounded-md hover:cursor-pointer group`}
                            >
                                <h3>All</h3>
                                <div className=' ml-auto mt-2'>
                                    <FaChevronRight
                                        className={`${
                                            activeButtonIndex === 0
                                                ? "text-white"
                                                : "text-transparent"
                                        } w-4 h-4 group-hover:text-white`}
                                    />
                                </div>
                            </div>

                            <div className='space-y-3'>
                                <div
                                    onClick={() => {
                                        setActiveButtonIndex(1);
                                        setEvent("Pictures");
                                        navigate("/media");
                                    }}
                                    className={`${
                                        activeButtonIndex === 1
                                            ? "bg-green"
                                            : ""
                                    }
                                    flex content-center px-3 py-2 justify-center text-xl hover:bg-green rounded-md hover:cursor-pointer group`}
                                >
                                    <h3>Pictures</h3>
                                    <div className=' ml-auto mt-2'>
                                        <FaChevronRight
                                            className={`${
                                                activeButtonIndex === 1
                                                    ? "text-white"
                                                    : "text-transparent"
                                            } w-4 h-4 group-hover:text-white`}
                                        />
                                    </div>
                                </div>
                                {Event === "Pictures" && (
                                    <div className='flex flex-col gap-1'>
                                        <button
                                            className={`py-1 ${
                                                pictureType === "Field Work"
                                                    ? "bg-[#7EFDE3] rounded-md"
                                                    : ""
                                            } w-full text-left px-3`}
                                            onClick={() =>
                                                setPictureType("Field Work")
                                            }
                                        >
                                            Field Work
                                        </button>
                                        <button
                                            className={`py-1 ${
                                                pictureType === "Office Work"
                                                    ? "bg-[#7EFDE3] rounded-md"
                                                    : ""
                                            } w-full text-left px-3`}
                                            onClick={() =>
                                                setPictureType("Office Work")
                                            }
                                        >
                                            Use Case
                                        </button>
                                        <button
                                            className={`py-1 ${
                                                pictureType === "Tele"
                                                    ? "bg-[#7EFDE3] rounded-md"
                                                    : ""
                                            } w-full text-left px-3`}
                                            onClick={() =>
                                                setPictureType("Tele")
                                            }
                                        >
                                            TeleCommunication{" "}
                                        </button>
                                    </div>
                                )}
                            </div>
                            <div
                                onClick={() => {
                                    setActiveButtonIndex(2);
                                    setEvent("Videos");
                                    navigate("/videos");
                                }}
                                className={`${
                                    activeButtonIndex === 2 ? "bg-green" : ""
                                }
                  flex content-center px-3 py-2 justify-center text-xl hover:bg-green rounded-md hover:cursor-pointer group`}
                            >
                                <h3>Videos</h3>
                                <div className=' ml-auto mt-2'>
                                    <FaChevronRight
                                        className={`${
                                            activeButtonIndex === 2
                                                ? "text-white"
                                                : "text-transparent"
                                        } w-4 h-4 group-hover:text-white`}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='border ml-7'></div>
                        {/* <hr className=' w-96 rotate-90' /> */}
                    </div>

                    {Event === "Pictures" && (
                        <Carousel
                            axis='horizontal'
                            className='relative w-[100%] pt-4 h-4/5'
                            showArrows={true}
                            showStatus={false}
                            showThumbs={false}
                            swipeable={true}
                            // infiniteLoop={true}
                            interval={2500}
                            swipeScrollTolerance={80}
                            transitionTime={1000}
                            renderIndicator={false}
                            // selectedItem={-1}
                            renderArrowPrev={(clickHandler, hasPrev) => {
                                return (
                                    <div
                                        className={` ${
                                            scrollPos === 0 ? 0 : "opacity-30"
                                        } absolute -left-4 flex justify-center items-center p-3 hover:opacity-100 cursor-pointer z-10`}
                                        style={{
                                            top: `calc(15% + ${scrollPos}px - ${
                                                windowHeight / 2
                                            }px)`,
                                            visibility:
                                                scrollPos === 0
                                                    ? "hidden"
                                                    : "visible", // Hide the arrow at the upper limit
                                        }}
                                        onClick={clickHandler}
                                    >
                                        <svg
                                            fill='#09384f'
                                            height='100px'
                                            width='100px'
                                            xmlns='http://www.w3.org/2000/svg'
                                            viewBox='0 0 300 800'
                                            xmlSpace='preserve'
                                            stroke='#09384f'
                                            strokeWidth={0.00511641}
                                            className=''
                                        >
                                            <path
                                                className='cls-arrow'
                                                d='M148.32 255.76L386.08 18c4.053-4.267 3.947-10.987-.213-15.04a10.763 10.763 0 00-14.827 0L125.707 248.293a10.623 10.623 0 000 15.04L371.04 508.667c4.267 4.053 10.987 3.947 15.04-.213a10.763 10.763 0 000-14.827L148.32 255.76z'
                                            />
                                        </svg>
                                    </div>
                                );
                            }}
                            renderArrowNext={(clickHandler, hasNext) => {
                                return (
                                    <div
                                        className={` ${
                                            scrollPos === 0 ? 0 : "opacity-30"
                                        } absolute right-14 flex justify-center items-center p-3 opacity-30 hover:opacity-100 cursor-pointer z-10`}
                                        style={{
                                            top: `calc(12% + ${scrollPos}px - ${
                                                windowHeight / 2
                                            }px)`,
                                            visibility:
                                                scrollPos === 0
                                                    ? "hidden"
                                                    : "visible", // Hide the arrow at the upper limit
                                        }}
                                        onClick={clickHandler}
                                    >
                                        <svg
                                            fill='#09384f'
                                            height='100px'
                                            width='100px'
                                            xmlns='http://www.w3.org/2000/svg'
                                            viewBox='0 0 300 800'
                                            xmlSpace='preserve'
                                            transform='rotate(180)'
                                            stroke='#09384f'
                                        >
                                            <path d='M148.32 255.76L386.08 18c4.053-4.267 3.947-10.987-.213-15.04a10.763 10.763 0 00-14.827 0L125.707 248.293a10.623 10.623 0 000 15.04L371.04 508.667c4.267 4.053 10.987 3.947 15.04-.213a10.763 10.763 0 000-14.827L148.32 255.76z' />
                                        </svg>
                                    </div>
                                );
                            }}
                        >
                            {pictureListFiltered?.map((imageList) => {
                                return (
                                    <div
                                        className={` ${
                                            imageList[0] ||
                                            imageList[1] ||
                                            imageList[2] ||
                                            imageList[3]
                                                ? "flex w-full h-full mt-6 justify-center"
                                                : "hidden"
                                        } `}
                                        key={0}
                                    >
                                        <div className='grid grid-cols-4 gap-4  font-medium text-green text-xl w-[85%] h-[50%] scale-90'>
                                            {imageList[0]?.image_type ===
                                            pictureType ? (
                                                <div
                                                    className='flex relative -ml-10 w-full h-full col-span-2 row-span-2 text-left hover:cursor-pointer hover:scale-105 z-20 transition-all duration-500'
                                                    onClick={() => {
                                                        toggleModal(
                                                            imageList[0]
                                                                .image_url,
                                                            imageList[0].caption
                                                        );
                                                    }}
                                                >
                                                    <div className='flex w-full h-[550px]'>
                                                        <img
                                                            src={
                                                                process.env
                                                                    .REACT_APP_BASE_URL +
                                                                imageList[0]
                                                                    .image_url
                                                            }
                                                            className='h-full w-full object-cover'
                                                            // className={`${Enlarge === 1 ? "scale-150" : "" } w-[100%] h-[100%] object-cover`} onClick={() => {setEnlarge(1)}}
                                                            alt='gallery img'
                                                        />
                                                    </div>

                                                    <div className='absolute bottom-0 left-0 right-0 px-4 py-20 bg-news-gradient'>
                                                        <p className='absolute bottom-0 p-7 text-white'>
                                                            {/* <TextTruncate
                                                                    line={2}
                                                                    element='span'
                                                                    truncateText='…'
                                                                    text={
                                                                        imageList[0]
                                                                            .caption
                                                                    }
                                                                    textTruncateChild={
                                                                        <Link className='font-bold text-green italic '>
                                                                            Read
                                                                            More
                                                                            ...{" "}
                                                                        </Link>
                                                                    }
                                                                /> */}
                                                            {parser(
                                                                turnicate(
                                                                    imageList[0]
                                                                        .caption
                                                                )
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                            {imageList[1]?.image_type ? (
                                                <div
                                                    className='flex relative -ml-10 w-full h-full col-span-2 row-span-2 text-left hover:cursor-pointer hover:scale-105 z-20 transition-all duration-500'
                                                    onClick={() => {
                                                        toggleModal(
                                                            imageList[1]
                                                                .image_url,
                                                            imageList[1].caption
                                                        );
                                                    }}
                                                >
                                                    <div className='flex w-full h-[550px]'>
                                                        <img
                                                            src={
                                                                process.env
                                                                    .REACT_APP_BASE_URL +
                                                                imageList[1]
                                                                    .image_url
                                                            }
                                                            className=' h-full w-full object-cover'
                                                            // className={`${Enlarge === 1 ? "scale-150" : "" } w-[100%] h-[100%] object-cover`} onClick={() => {setEnlarge(1)}}
                                                            alt='gallery img'
                                                        />
                                                    </div>

                                                    <div className='absolute bottom-0 left-0 right-0 px-4 py-20 bg-news-gradient'>
                                                        <p className='absolute bottom-0 p-7 text-white'>
                                                            {/* <TextTruncate
                                                                    line={2}
                                                                    element='span'
                                                                    truncateText='…'
                                                                    text={
                                                                        imageList[1]
                                                                            .caption
                                                                    }
                                                                    textTruncateChild={
                                                                        <Link className='font-bold text-green italic '>
                                                                            Read
                                                                            More
                                                                            ...{" "}
                                                                        </Link>
                                                                    }
                                                                /> */}
                                                            {parser(
                                                                turnicate(
                                                                    imageList[1]
                                                                        .caption
                                                                )
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                            {imageList[2]?.image_type ? (
                                                <div
                                                    className='flex relative -ml-10 w-full h-full col-span-2 row-span-2 text-left hover:cursor-pointer hover:scale-105 z-20 transition-all duration-500'
                                                    onClick={() => {
                                                        toggleModal(
                                                            imageList[2]
                                                                .image_url,
                                                            imageList[2].caption
                                                        );
                                                    }}
                                                >
                                                    <div className='flex w-full h-[550px]'>
                                                        <img
                                                            src={
                                                                process.env
                                                                    .REACT_APP_BASE_URL +
                                                                imageList[2]
                                                                    .image_url
                                                            }
                                                            className=' h-full w-full object-cover'
                                                            // className={`${Enlarge === 1 ? "scale-150" : "" } w-[100%] h-[100%] object-cover`} onClick={() => {setEnlarge(1)}}
                                                            alt='gallery img'
                                                        />
                                                    </div>

                                                    <div className='absolute bottom-0 left-0 right-0 px-4 py-20 bg-news-gradient'>
                                                        <p className='absolute bottom-0 p-7 text-white'>
                                                            {/* <TextTruncate
                                                                    line={2}
                                                                    element='span'
                                                                    truncateText='…'
                                                                    text={
                                                                        imageList[2]
                                                                            .caption
                                                                    }
                                                                    textTruncateChild={
                                                                        <Link className='font-bold text-green italic '>
                                                                            Read
                                                                            More
                                                                            ...{" "}
                                                                        </Link>
                                                                    }
                                                                /> */}
                                                            {parser(
                                                                turnicate(
                                                                    imageList[2]
                                                                        .caption
                                                                )
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                            {imageList[3]?.image_type ? (
                                                <div
                                                    className='flex relative -ml-10 w-full h-full col-span-2 row-span-2 text-left hover:cursor-pointer hover:scale-105 z-20 transition-all duration-500'
                                                    onClick={() => {
                                                        toggleModal(
                                                            imageList[3]
                                                                .image_url,
                                                            imageList[3].caption
                                                        );
                                                    }}
                                                >
                                                    <div className='flex w-full h-[550px]'>
                                                        <img
                                                            src={
                                                                process.env
                                                                    .REACT_APP_BASE_URL +
                                                                imageList[3]
                                                                    .image_url
                                                            }
                                                            className=' h-full w-full object-cover'
                                                            // className={`${Enlarge === 1 ? "scale-150" : "" } w-[100%] h-[100%] object-cover`} onClick={() => {setEnlarge(1)}}
                                                            alt='gallery img'
                                                        />
                                                    </div>

                                                    <div className='absolute bottom-0 left-0 right-0 px-4 py-20 bg-news-gradient'>
                                                        <p className='absolute bottom-0 p-7 text-white'>
                                                            {/* <TextTruncate
                                                                    line={2}
                                                                    element='span'
                                                                    truncateText='…'
                                                                    text={
                                                                        imageList[3]
                                                                            .caption
                                                                    }
                                                                    textTruncateChild={
                                                                        <Link className='font-bold text-green italic '>
                                                                            Read
                                                                            More
                                                                            ...{" "}
                                                                        </Link>
                                                                    }
                                                                /> */}

                                                            {parser(
                                                                turnicate(
                                                                    imageList[3]
                                                                        .caption
                                                                )
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </Carousel>
                    )}
                    {/* {Event === "Videos" && (
                        <Carousel
                            axis='horizontal'
                            className='relative w-[100%] pt-4 h-4/5'
                            showArrows={true}
                            showStatus={false}
                            showThumbs={false}
                            swipeable={true}
                            // infiniteLoop={true}
                            interval={2500}
                            swipeScrollTolerance={80}
                            transitionTime={1000}
                            renderIndicator={false}
                            // selectedItem={-1}
                            renderArrowPrev={(clickHandler, hasPrev) => {
                                return (
                                    <div
                                        className={` ${
                                            scrollPos === 0 ? 0 : "opacity-30"
                                        } absolute -left-4 flex justify-center items-center p-3 hover:opacity-100 cursor-pointer z-10`}
                                        style={{
                                            top: `calc(15% + ${scrollPos}px - ${
                                                windowHeight / 2
                                            }px)`,
                                            visibility:
                                                scrollPos === 0
                                                    ? "hidden"
                                                    : "visible", // Hide the arrow at the upper limit
                                        }}
                                        onClick={clickHandler}
                                    >
                                        <svg
                                            fill='#09384f'
                                            height='100px'
                                            width='100px'
                                            xmlns='http://www.w3.org/2000/svg'
                                            viewBox='0 0 300 800'
                                            xmlSpace='preserve'
                                            stroke='#09384f'
                                            strokeWidth={0.00511641}
                                            className=''
                                        >
                                            <path
                                                className='cls-arrow'
                                                d='M148.32 255.76L386.08 18c4.053-4.267 3.947-10.987-.213-15.04a10.763 10.763 0 00-14.827 0L125.707 248.293a10.623 10.623 0 000 15.04L371.04 508.667c4.267 4.053 10.987 3.947 15.04-.213a10.763 10.763 0 000-14.827L148.32 255.76z'
                                            />
                                        </svg>
                                    </div>
                                );
                            }}
                            renderArrowNext={(clickHandler, hasNext) => {
                                return (
                                    <div
                                        className={` ${
                                            scrollPos === 0 ? 0 : "opacity-30"
                                        } absolute right-16 flex justify-center items-center p-3 opacity-30 hover:opacity-100 cursor-pointer z-10`}
                                        style={{
                                            top: `calc(12% + ${scrollPos}px - ${
                                                windowHeight / 2
                                            }px)`,
                                            visibility:
                                                scrollPos === 0
                                                    ? "hidden"
                                                    : "visible", // Hide the arrow at the upper limit
                                        }}
                                        onClick={clickHandler}
                                    >
                                        <svg
                                            fill='#09384f'
                                            height='100px'
                                            width='100px'
                                            xmlns='http://www.w3.org/2000/svg'
                                            viewBox='0 0 300 800'
                                            xmlSpace='preserve'
                                            transform='rotate(180)'
                                            stroke='#09384f'
                                        >
                                            <path d='M148.32 255.76L386.08 18c4.053-4.267 3.947-10.987-.213-15.04a10.763 10.763 0 00-14.827 0L125.707 248.293a10.623 10.623 0 000 15.04L371.04 508.667c4.267 4.053 10.987 3.947 15.04-.213a10.763 10.763 0 000-14.827L148.32 255.76z' />
                                        </svg>
                                    </div>
                                );
                            }}
                        >
                            {videoLists?.map((videoList) => {
                                const shouldRenderVideos =
                                    videoList[0] ||
                                    videoList[1] ||
                                    videoList[2] ||
                                    videoList[3];
                                return (
                                    <div
                                        className={`${
                                            shouldRenderVideos
                                                ? "flex"
                                                : "hidden"
                                        } w-full h-full mt-6 justify-center -ml-9`}
                                        key={0}
                                    >
                                        <div className='grid grid-cols-4 gap-8  font-medium text-green text-xl w-[85%] h-[50%] scale-90'>
                                            {videoList[0] ? (
                                                <Suspense
                                                    fallback={
                                                        <div>Loading...</div>
                                                    }
                                                >
                                                    <LazyIframe
                                                        url={
                                                            videoList[0]
                                                                .video_url
                                                        }
                                                        bgImg={
                                                            videoList[0]
                                                                .image_url
                                                        }
                                                        toggleVid={
                                                            toggleVidModal
                                                        }
                                                    />
                                                </Suspense>
                                            ) : (
                                                ""
                                            )}

                                            {videoList[1] ? (
                                                <Suspense
                                                    fallback={
                                                        <div>Loading...</div>
                                                    }
                                                >
                                                    <LazyIframe
                                                        url={
                                                            videoList[1]
                                                                .video_url
                                                        }
                                                        bgImg={
                                                            videoList[1]
                                                                .image_url
                                                        }
                                                        toggleVid={
                                                            toggleVidModal
                                                        }
                                                    />
                                                </Suspense>
                                            ) : (
                                                ""
                                            )}

                                            {videoList[2] ? (
                                                <Suspense
                                                    fallback={
                                                        <div>Loading...</div>
                                                    }
                                                >
                                                    <LazyIframe
                                                        url={
                                                            videoList[2]
                                                                .video_url
                                                        }
                                                        bgImg={
                                                            videoList[2]
                                                                .image_url
                                                        }
                                                        toggleVid={
                                                            toggleVidModal
                                                        }
                                                    />
                                                </Suspense>
                                            ) : (
                                                ""
                                            )}

                                            {videoList[3] ? (
                                                <Suspense
                                                    fallback={
                                                        <div>Loading...</div>
                                                    }
                                                >
                                                    <LazyIframe
                                                        url={
                                                            videoList[3]
                                                                .video_url
                                                        }
                                                        bgImg={
                                                            videoList[3]
                                                                .image_url
                                                        }
                                                        toggleVid={
                                                            toggleVidModal
                                                        }
                                                    />
                                                </Suspense>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </Carousel>
                    )} */}
                    {Event === "Videos" && <Video videosLists={videoLists} />}
                    {Event === "All" && (
                        <Carousel
                            axis='horizontal'
                            className='relative w-[100%] pt-4 h-4/5'
                            showArrows={true}
                            showStatus={false}
                            showThumbs={false}
                            swipeable={true}
                            // infiniteLoop={true}
                            interval={2500}
                            swipeScrollTolerance={80}
                            transitionTime={1000}
                            renderIndicator={false}
                            selectedItem={carouselSelectedIndex}
                            onChange={setCarouselSelectedIndex}
                            renderArrowPrev={(clickHandler, hasPrev) => {
                                return (
                                    <div
                                        className={` ${
                                            scrollPos === 0 ? 0 : "opacity-30"
                                        } absolute -left-4 flex justify-center items-center p-3 hover:opacity-100 cursor-pointer z-10`}
                                        style={{
                                            top: `calc(15% + ${scrollPos}px - ${
                                                windowHeight / 2
                                            }px)`,
                                            visibility:
                                                scrollPos === 0
                                                    ? "hidden"
                                                    : "visible", // Hide the arrow at the upper limit
                                        }}
                                        onClick={clickHandler}
                                    >
                                        <svg
                                            fill='#09384f'
                                            height='100px'
                                            width='100px'
                                            xmlns='http://www.w3.org/2000/svg'
                                            viewBox='0 0 300 800'
                                            xmlSpace='preserve'
                                            stroke='#09384f'
                                            strokeWidth={0.00511641}
                                            className=''
                                        >
                                            <path
                                                className='cls-arrow'
                                                d='M148.32 255.76L386.08 18c4.053-4.267 3.947-10.987-.213-15.04a10.763 10.763 0 00-14.827 0L125.707 248.293a10.623 10.623 0 000 15.04L371.04 508.667c4.267 4.053 10.987 3.947 15.04-.213a10.763 10.763 0 000-14.827L148.32 255.76z'
                                            />
                                        </svg>
                                    </div>
                                );
                            }}
                            renderArrowNext={(clickHandler, hasNext) => {
                                return (
                                    <div
                                        className={` ${
                                            scrollPos === 0 ? 0 : "opacity-30"
                                        } absolute right-14 flex justify-center items-center p-3 opacity-30 hover:opacity-100 cursor-pointer z-10`}
                                        style={{
                                            top: `calc(12% + ${scrollPos}px - ${
                                                windowHeight / 2
                                            }px)`,
                                            visibility:
                                                scrollPos === 0
                                                    ? "hidden"
                                                    : "visible", // Hide the arrow at the upper limit
                                        }}
                                        onClick={clickHandler}
                                    >
                                        <svg
                                            fill='#09384f'
                                            height='100px'
                                            width='100px'
                                            xmlns='http://www.w3.org/2000/svg'
                                            viewBox='0 0 300 800'
                                            xmlSpace='preserve'
                                            transform='rotate(180)'
                                            stroke='#09384f'
                                        >
                                            <path d='M148.32 255.76L386.08 18c4.053-4.267 3.947-10.987-.213-15.04a10.763 10.763 0 00-14.827 0L125.707 248.293a10.623 10.623 0 000 15.04L371.04 508.667c4.267 4.053 10.987 3.947 15.04-.213a10.763 10.763 0 000-14.827L148.32 255.76z' />
                                        </svg>
                                    </div>
                                );
                            }}
                        >
                            {lists?.map((allList) => {
                                return (
                                    <div
                                        className={` ${
                                            allList[0] ||
                                            allList[1] ||
                                            allList[2] ||
                                            allList[3]
                                                ? "flex w-full h-full mt-6 justify-center"
                                                : "hidden"
                                        } `}
                                        key={0}
                                    >
                                        <div className='grid grid-cols-4 gap-4  font-medium text-green text-xl w-[85%] h-[50%] scale-90'>
                                            {allList[0] ? (
                                                <div
                                                    className='relative -ml-10 w-[100.5%] h-full col-span-2 row-span-1 text-left hover:cursor-pointer hover:scale-105 z-20 transition-all duration-500'
                                                    onClick={() => {
                                                        allList[0].image_url
                                                            ? toggleModal(
                                                                  allList[0]
                                                                      .image_url,
                                                                  allList[0]
                                                                      .caption
                                                              )
                                                            : toggleVidModal(
                                                                  allList[0]
                                                                      .video_url
                                                              );
                                                    }}
                                                >
                                                    {allList[0].type ===
                                                    "Image" ? (
                                                        <div className='flex w-full h-[550px]'>
                                                            <img
                                                                src={
                                                                    process.env
                                                                        .REACT_APP_BASE_URL +
                                                                    allList[0]
                                                                        .image_url
                                                                }
                                                                className=' h-full w-full object-cover'
                                                                // className={`${Enlarge === 1 ? "scale-150" : "" } w-[100%] h-[100%] object-cover`} onClick={() => {setEnlarge(1)}}
                                                                alt='gallery img'
                                                            />

                                                            <div className='absolute bottom-0 left-0 right-0 px-4 py-20 bg-news-gradient'>
                                                                <p className='absolute bottom-0 p-7 text-white'>
                                                                    {parser(
                                                                        turnicate(
                                                                            allList[0]
                                                                                .caption
                                                                        )
                                                                    )}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ) : allList[0].type ===
                                                      "Video" ? (
                                                        <div className='flex w-[115%] h-[550px] -ml-10'>
                                                            <Iframe
                                                                url={
                                                                    allList[0]
                                                                        .video_url
                                                                }
                                                                className='w-[100%] h-[100%] flex -z-20 relative'
                                                                title='YouTube video player'
                                                                frameborder='0'
                                                                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                                                                allowfullscreen
                                                            />
                                                        </div>
                                                    ) : null}
                                                </div>
                                            ) : (
                                                ""
                                            )}

                                            {allList[1] ? (
                                                <div
                                                    className='relative -ml-10 w-full h-full col-span-2 row-span-1 text-left hover:cursor-pointer hover:scale-105 z-20 transition-all duration-500'
                                                    onClick={() => {
                                                        allList[1].image_url
                                                            ? toggleModal(
                                                                  allList[1]
                                                                      .image_url,
                                                                  allList[1]
                                                                      .caption
                                                              )
                                                            : toggleVidModal(
                                                                  allList[1]
                                                                      .video_url
                                                              );
                                                    }}
                                                >
                                                    {allList[1].type ===
                                                    "Image" ? (
                                                        <div className='flex w-full h-[550px]'>
                                                            <img
                                                                src={
                                                                    process.env
                                                                        .REACT_APP_BASE_URL +
                                                                    allList[1]
                                                                        .image_url
                                                                }
                                                                className=' h-full w-full object-cover'
                                                                // className={`${Enlarge === 1 ? "scale-150" : "" } w-[100%] h-[100%] object-cover`} onClick={() => {setEnlarge(1)}}
                                                                alt='gallery img'
                                                            />

                                                            <div className='absolute bottom-0 left-0 right-0 px-4 py-20 bg-news-gradient'>
                                                                <p className='absolute bottom-0 p-7 text-white'>
                                                                    {parser(
                                                                        turnicate(
                                                                            allList[1]
                                                                                .caption
                                                                        )
                                                                    )}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ) : allList[1].type ===
                                                      "Video" ? (
                                                        <div className='flex w-[115%] h-[550px] -ml-10'>
                                                            <Iframe
                                                                url={
                                                                    allList[1]
                                                                        .video_url
                                                                }
                                                                className='w-[100%] h-[100%] flex -z-20 relative'
                                                                title='YouTube video player'
                                                                frameborder='0'
                                                                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                                                                allowfullscreen
                                                            />
                                                        </div>
                                                    ) : null}
                                                </div>
                                            ) : (
                                                ""
                                            )}

                                            {allList[2] ? (
                                                <div
                                                    className='relative -ml-10 w-full h-full col-span-2 row-span-1 text-left hover:cursor-pointer hover:scale-105 z-20 transition-all duration-500'
                                                    onClick={() => {
                                                        allList[2].image_url
                                                            ? toggleModal(
                                                                  allList[2]
                                                                      .image_url,
                                                                  allList[2]
                                                                      .caption
                                                              )
                                                            : toggleVidModal(
                                                                  allList[2]
                                                                      .video_url
                                                              );
                                                    }}
                                                >
                                                    {allList[2].type ===
                                                    "Image" ? (
                                                        <div className='flex w-full h-[550px]'>
                                                            <img
                                                                src={
                                                                    process.env
                                                                        .REACT_APP_BASE_URL +
                                                                    allList[2]
                                                                        .image_url
                                                                }
                                                                className=' h-full w-full object-cover'
                                                                // className={`${Enlarge === 1 ? "scale-150" : "" } w-[100%] h-[100%] object-cover`} onClick={() => {setEnlarge(1)}}
                                                                alt='gallery img'
                                                            />

                                                            <div className='absolute bottom-0 left-0 right-0 px-4 py-20 bg-news-gradient'>
                                                                <p className='absolute bottom-0 p-7 text-white'>
                                                                    {parser(
                                                                        turnicate(
                                                                            allList[2]
                                                                                .caption
                                                                        )
                                                                    )}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ) : allList[2].type ===
                                                      "Video" ? (
                                                        <div className='flex w-[115%] h-[550px] -ml-10'>
                                                            <Iframe
                                                                url={
                                                                    allList[2]
                                                                        .video_url
                                                                }
                                                                className='w-[100%] h-[100%] flex -z-20 relative'
                                                                title='YouTube video player'
                                                                frameborder='0'
                                                                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                                                                allowfullscreen
                                                            />
                                                        </div>
                                                    ) : null}
                                                </div>
                                            ) : (
                                                ""
                                            )}

                                            {allList[3] ? (
                                                <div
                                                    className='relative -ml-10 w-full h-full col-span-2 row-span-1 text-left hover:cursor-pointer hover:scale-105 z-20 transition-all duration-500'
                                                    onClick={() => {
                                                        allList[3].image_url
                                                            ? toggleModal(
                                                                  allList[3]
                                                                      .image_url,
                                                                  allList[3]
                                                                      .caption
                                                              )
                                                            : toggleVidModal(
                                                                  allList[3]
                                                                      .video_url
                                                              );
                                                    }}
                                                >
                                                    {allList[3].type ===
                                                    "Image" ? (
                                                        <div className='flex w-full h-[550px]'>
                                                            <img
                                                                src={
                                                                    process.env
                                                                        .REACT_APP_BASE_URL +
                                                                    allList[3]
                                                                        .image_url
                                                                }
                                                                className=' h-full w-full object-cover'
                                                                // className={`${Enlarge === 1 ? "scale-150" : "" } w-[100%] h-[100%] object-cover`} onClick={() => {setEnlarge(1)}}
                                                                alt='gallery img'
                                                            />

                                                            <div className='absolute bottom-0 left-0 right-0 px-4 py-20 bg-news-gradient'>
                                                                <p className='absolute bottom-0 p-7 text-white'>
                                                                    {parser(
                                                                        turnicate(
                                                                            allList[3]
                                                                                .caption
                                                                        )
                                                                    )}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ) : allList[3].type ===
                                                      "Video" ? (
                                                        <div className='flex w-[115%] h-[550px] -ml-10'>
                                                            <Iframe
                                                                url={
                                                                    allList[3]
                                                                        .video_url
                                                                }
                                                                className='w-[100%] h-[100%] flex -z-20 relative'
                                                                title='YouTube video player'
                                                                frameborder='0'
                                                                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                                                                allowfullscreen
                                                            />
                                                        </div>
                                                    ) : null}
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </Carousel>
                    )}
                </div>
            </div>
        </>
    );
};

export default GalleryComponent;
