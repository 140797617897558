import React from "react";
import { RemoveScroll } from "react-remove-scroll";
import ReactDom from "react-dom";
import { RiCloseCircleLine } from "react-icons/ri";

const Modal = ({ open, handleClose, children }) => {
    if (!open) return null;

    console.log("close", handleClose);
    return ReactDom.createPortal(
        <div className='fixed top-0 left-0 right-0 h-screen bg-black/60 overflow-clip z-50'>
            <RemoveScroll className='flex justify-center h-full flex-col'>
                <div
                    className='absolute top-0 w-full h-full z-30'
                    onClick={() => handleClose}
                ></div>

                <div className='mx-auto w-full max-w-[1400px] z-40'>
                    {children}
                </div>
            </RemoveScroll>
        </div>,
        document.getElementById("portal")
    );
};

export default Modal;
